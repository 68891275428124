import { classNames } from '@axo/shared/util/dom';
import { ForwardRefRenderFunction, forwardRef, useId } from 'react';
import { RegisterOptions } from 'react-hook-form';
import InputInfoBox, { IInputInfoBox, InfoType } from '../InputInfoBox';

import { IInputProps } from '../../models/input.model';
import InputWrapper from '../InputWrapper';
import Radio, { IRadio, IRadioStyle } from '../Radio';

import { useTranslation } from '@axo/shared/i18n';
import styles from './RadioGroup.module.scss';

export type IOption = {
  value: string;
  text: string;
  label?: string;
};

export interface IRadioGroup extends IInputProps<HTMLInputElement, string> {
  options: IOption[];
  radioStyle?: IRadioStyle;
  infoBox?: IInputInfoBox;
  label?: string;
  warning?: string;
  name: string;
  validation?: RadioValidation;
  buttonHeight?: 'large' | 'regular';
  setButtonsHalfWidth?: boolean;
  variant?: IRadio['variant'];
  testid?: string;
}

export type RadioValidation = Pick<RegisterOptions, 'required'>;

const RadioGroup: ForwardRefRenderFunction<HTMLInputElement, IRadioGroup> = (
  {
    options,
    radioStyle,
    infoBox,
    label,
    warning,
    setButtonsHalfWidth,
    testid,
    ...props
  },
  ref
) => {
  const { t } = useTranslation();

  const MAX_NUMBER_OF_CHILDREN = 6;

  const labelId = useId();
  const infoId = useId();

  const isError = infoBox?.type === InfoType.ERROR;

  return (
    <InputWrapper label={label} labelId={labelId}>
      <div className={styles.wrapper}>
        <div
          className={classNames(
            styles.group,
            setButtonsHalfWidth ? styles.buttonsHalfWidth : undefined
          )}
          role="radiogroup"
          data-testid={testid}
          aria-labelledby={labelId}
          aria-errormessage={isError ? infoId : undefined}
          aria-describedby={!isError && infoBox?.type ? infoId : undefined}
        >
          {options
            .slice(0, MAX_NUMBER_OF_CHILDREN)
            .map(({ text, value, label }) => {
              return (
                <Radio
                  key={text}
                  id={text}
                  severity="warning"
                  value={value}
                  radioStyle={radioStyle}
                  ref={ref}
                  buttonHeight="large"
                  classes={{ root: styles.buttonHolder }}
                  label={label}
                  {...props}
                >
                  {t(text)}
                </Radio>
              );
            })}
        </div>
        {infoBox && <InputInfoBox {...infoBox} infoId={infoId} />}
      </div>
    </InputWrapper>
  );
};

export default forwardRef(RadioGroup);
