import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  insuranceCustomerKeys,
  parseInsuranceCustomerKey,
} from '../cache-keys/insuranceCustomerKeys';
import { insurance_payment_api } from '@axo/shared/data-access/api';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { queryFunction } from '../query-function';

export function useInsurancePayments(policyID: string, marketCountry: string) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();

  const cacheKey = insuranceCustomerKeys.root({
    insuranceID: policyID ?? '',
    customerID: state.user.customerID ?? '',
    marketCountry: marketCountry ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(insurance_payment_api.findCardMandates(api), ({ queryKey }) =>
      parseInsuranceCustomerKey(queryKey)
    ),
    {
      enabled: !!(policyID && state.user.customerID && marketCountry),
    }
  );
}
