export interface API {
  token: string;
  send: <R, B = unknown>(req: APIRequest<B>) => Promise<APIResponse<R>>;
  urls: {
    api: string;
  };
}

export type APIHeaders = Record<
  'Authorization' | 'Content-Type' | 'Accept' | string,
  string
>;

export interface APIRequest<B = unknown> {
  url: string;
  method?:
    | 'get'
    | 'GET'
    | 'delete'
    | 'DELETE'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH';
  data?: B;
  headers?: APIHeaders;
}

export interface APIResponse<R = unknown> {
  data: R;
  status: number;
  headers: APIHeaders;
}

export class APIError extends Error {
  constructor(message: string, public readonly response: Response) {
    super(message);
  }

  get statusCode() {
    return this.response.status;
  }
}
