import { classNames } from '@axo/shared/util/dom';
import { INavigationButton, NavigationButton } from './NavigationButton';
import styles from './StepNavigator.module.scss';

export interface IStepNavigator {
  rightButton?: INavigationButton;
  leftButton?: INavigationButton;
  layout?: 'vertical' | 'horizontal';
}

export const StepNavigator = ({ ...props }: IStepNavigator) => {
  return (
    <div
      className={classNames(
        styles.buttons,
        styles[props.layout ?? 'horizontal']
      )}
    >
      {props.leftButton && <NavigationButton {...props.leftButton} />}
      {props.rightButton && <NavigationButton {...props.rightButton} />}
    </div>
  );
};
