import { insurance_payment } from '@axo/shared/data-access/types';

function canUsePayment(x: insurance_payment.Payment) {
  return (
    x.Status === insurance_payment.Status.Pending ||
    x.Status === insurance_payment.Status.Completed
  );
}

export const useExistingPayment = (
  payment: insurance_payment.Payment | null,
  payments?: insurance_payment.Payment[]
): insurance_payment.Payment | null => {
  const prev = payment;

  if (prev && canUsePayment(prev)) {
    return prev;
  }

  const usable = (payments ?? []).filter(canUsePayment);
  const sorted = usable.sort(
    (a, b) => +new Date(b.CreatedAt) - +new Date(a.CreatedAt)
  );

  if (sorted.length) {
    return sorted[0];
  }

  return null;
};
