import { ReactNode, useContext, useEffect } from 'react';
import { StepTitle } from '../StepTitle';
import { StepContext } from './store/context';
import { IStepName } from './store/stepStates';

export function CurrentStep({
  component,
  name,
}: {
  component: ReactNode;
  name: IStepName;
}) {
  const { dispatch, state, isEmbedded } = useContext(StepContext);

  const stepData = state.steps[name];

  useEffect(() => {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { state: 'active' },
    });
  }, [stepData.name, dispatch]);

  return (
    <>
      {stepData.isHeadlineShown && (
        <StepTitle title={stepData.title} desktopOnly={!isEmbedded} />
      )}
      {component}
    </>
  );
}
