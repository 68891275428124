import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import styles from './Accordion.module.scss';

// https://www.radix-ui.com/primitives/docs/components/accordion#api-reference
type IAccordionProps = {
  children?: ReactNode;
  className?: string;
} & (
  | {
      /**
       * The type of accordion. Use `single` if only one accordion item can be expanded at a time.
       */
      type: 'single';
      /**
       * The controlled stateful value of the accordion item whose content is expanded.
       */
      value?: string;
      /**
       * The value of the item whose content is expanded when the accordion is initially rendered. Use
       * `defaultValue` if you do not need to control the state of an accordion.
       */
      defaultValue?: string;
      /**
       * The callback that fires when the state of the accordion changes.
       */
      onValueChange?(value: string): void;
      /**
       * Whether an accordion item can be collapsed after it has been opened.
       * @default false
       */
      collapsible?: boolean;
    }
  | {
      /**
       * The type of accordion. Use `multiple` if multiple accordion items can be expanded at a time.
       */
      type: 'multiple';
      /**
       * The controlled stateful value of the accordion items whose contents are expanded.
       */
      value?: string[];
      /**
       * The value of the items whose contents are expanded when the accordion is initially rendered. Use
       * `defaultValue` if you do not need to control the state of an accordion.
       */
      defaultValue?: string[];
      /**
       * The callback that fires when the state of the accordion changes.
       */
      onValueChange?(value: string[]): void;
    }
);

export const Accordion = ({
  children,
  className,
  ...accordionProps
}: IAccordionProps): ReactElement => {
  return (
    <RadixAccordion.Root
      className={clsx(styles.accordion, className)}
      {...accordionProps}
    >
      {children}
    </RadixAccordion.Root>
  );
};
