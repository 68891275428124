import { useContext, useEffect } from 'react';
import { BreadcrumbContext } from './breadcrumb-context';


export function useEventField(key: string, value: string | null | undefined) {
  const context = useContext(BreadcrumbContext);
  if (!context) throw new Error('No BreadcrumbContext found!');
  const { addField } = context;

  useEffect(() => {
    if (value) {
      return addField({ Key: key, Value: value });
    }
    return undefined;
  }, [key, value, addField]);
}
