import { ReactNode, useReducer } from 'react';
import { DataAccessState, initialState, reducer } from './store/reducer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DataAccessContext } from './data-access-context';

export type ServicesBaseURL = {
  api: string;
  ws?: string;
  integration?: string;
};

export function DataAccessProvider({
  queryClient,
  children,
  url,
  storedInitialState,
}: {
  queryClient: QueryClient;
  children: ReactNode;
  url: ServicesBaseURL;
  storedInitialState?: DataAccessState;
}) {
  const initialStateWithServicesURL = storedInitialState ?? initialState;

  const [state, dispatch] = useReducer(reducer, initialStateWithServicesURL);

  return (
    <DataAccessContext.Provider value={{ queryClient, state, dispatch, url }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </DataAccessContext.Provider>
  );
}
