export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * <li id="whatever">test</li> to string[]
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const getStringsFromHTMLList = (list: string): string[] => {
  const listItems: string[] = [];
  const matches = list.matchAll(/<li[^>]*>(.*?)<\/li>/gis);

  for (const match of matches) {
    listItems.push(match[1].replaceAll(/<li[^>]*>(.*?)<\/li>/gis, ''));
  }

  return listItems;
};
