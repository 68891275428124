import {
  LDFlagValue,
  useFlags as _useFlags,
} from 'launchdarkly-react-client-sdk';
import { FlagName, FlagSetMap } from './Flag.types';

/**
 * @param flagSet - An object where the keys start with 'flag_' and the values are LD flag keys.
 * @returns An object where the keys are the same as in flagSet, and the values are the corresponding flag values from LD.
 */
export function useTypedFlags<T extends FlagSetMap<T>>(
  flagSet: T
): Record<keyof typeof flagSet, LDFlagValue> {
  type LDFlags = Record<T[keyof T], LDFlagValue>;
  const ldFlags = _useFlags<LDFlags>();

  // FIXME: Filtering is evaluating all the flags on every hook call, meaning is counted as Flag Evaluation on Launch Darkly and therefore skew experiments data.
  return Object.entries(flagSet).reduce((flags, [flag, key]) => {
    return { ...flags, [flag as FlagName]: ldFlags[key as T[keyof T]] };
  }, {} as Record<keyof T, LDFlagValue>);
}

export function useFlags<T extends FlagSetMap<T>>(
  flagSet: T
): Record<keyof typeof flagSet, LDFlagValue> {
  type LDFlags = Record<T[keyof T], LDFlagValue>;
  return _useFlags<LDFlags>() as T;
}
