import {
  InsuranceContext,
  InsuranceState,
  StepContext,
  stepperReducer,
} from '@axo/insurance/feature/providers';
import { Spinner } from '@axo/insurance/ui';
import { EventCode, useEventLogger } from '@axo/shared/data-access/event-log';
import { useInsurancePolicy } from '@axo/shared/data-access/hooks';
import {
  DataAccessContext,
  DataAccessState,
} from '@axo/shared/data-access/provider';
import { insurance_payment } from '@axo/shared/data-access/types';
import {
  LoanApplicationContext,
  LoanApplicationState,
} from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext } from 'react';
import {
  VerifyPaymentCard as SharedVerifyPaymentCard,
  useCreateInsurancePaymentEpic,
} from '../../../shared';

export const VerifyPaymentCard = () => {
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { state: insuranceContext } = useContext(InsuranceContext);
  const { dispatch, state, isEmbedded } = useContext(StepContext);
  const {
    steps: { verifyPaymentCard },
  } = state;
  const { payment, isCompleted } = verifyPaymentCard;
  const { insurancePolicyReference } = insuranceContext;
  const insurancePolicy = useInsurancePolicy(
    insurancePolicyReference?.PolicyID
  )?.data;
  const log = useEventLogger();
  const { track } = useAnalytics();

  type States = {
    dataAccessContext: DataAccessState;
    applicationContext: LoanApplicationState;
    insuranceContext: InsuranceState;
  };

  const createInsurancePayment = useCreateInsurancePaymentEpic<States>({
    states: { dataAccessContext, applicationContext, insuranceContext },
    stepperState: state,
    stepperDispatch: dispatch,
    reducer: stepperReducer,
    completeAction: {
      type: 'Set step data',
      scope: { parentType: 'verifyPaymentCard' },
      payload: {
        isCompleted: true,
      },
    },
  });

  if (payment === null || insurancePolicy === undefined) {
    return <Spinner />;
  }

  const handleNext = () => {
    log(EventCode.InsuranceNext);
    dispatch({
      type: 'Set step data',
      scope: { parentType: verifyPaymentCard.name },
      payload: { ...verifyPaymentCard, state: 'completed' },
    });
    track({
      event: `Insurance Step ${verifyPaymentCard.name} Completed`,
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'summary',
    });
  };

  return (
    <SharedVerifyPaymentCard
      isEmbedded={isEmbedded}
      payment={payment}
      isComplete={isCompleted}
      onNext={handleNext}
      onBack={() => {
        dispatch({
          type: 'Set step data',
          scope: { parentType: 'verifyPaymentCard' },
          payload: {
            state: 'touched',
          },
        });
        dispatch({
          type: 'Set step data',
          scope: { parentType: 'powerOfAttorney' },
          payload: {
            state: 'active',
          },
        });
        dispatch({
          type: 'Set step',
          scope: { parentType: 'step' },
          payload: 'powerOfAttorney',
        });
      }}
      onCreatePayment={(newPayment) => {
        if (payment?.Status === insurance_payment.Status.Pending) {
          dispatch({
            type: 'Set step data',
            scope: { parentType: 'verifyPaymentCard' },
            payload: {
              ...verifyPaymentCard,
              payment: {
                ...verifyPaymentCard.payment,
                ...newPayment,
              },
            },
          });
        }
      }}
      onCompletePayment={() => {
        dispatch({
          type: 'Set step data',
          scope: { parentType: 'verifyPaymentCard' },
          payload: { isCompleted: true },
        });
      }}
      onRetry={() => {
        createInsurancePayment.create(insurancePolicy, insurancePolicy.Amount);
      }}
    />
  );
};
