// Record of key (FF name) and list of possible values
export const featureFlagsConfig = {
  flag_ins_coverage_default_amount_fi: [
    200, 300, 400, 500, 600, 700, 800, 900, 1000,
  ] as const,
  flag_ins_coverage_default_amount_no: [
    2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
  ] as const,
};

export type InsuranceFeatureFlagsValues = {
  [K in keyof typeof featureFlagsConfig]: (typeof featureFlagsConfig)[K][number];
};

type InsuranceFeatureFlagsList = {
  [flagName in keyof typeof featureFlagsConfig]: flagName;
};

export const InsuranceFeatureFlags: InsuranceFeatureFlagsList = Object.keys(
  featureFlagsConfig
).reduce(
  (result, key) => ({ ...result, [key]: key }),
  {} as InsuranceFeatureFlagsList
);
