import { useContext } from 'react';
import { useQuery } from 'react-query';
import { parsePersonKey, personKeys } from '../cache-keys/personKeys';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { person_api } from '@axo/shared/data-access/api';
import { queryFunction } from '../query-function';

export function usePerson(personID: string | undefined | null) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();
  const cacheKey = personKeys.root({
    personID,
  });
  return useQuery(
    cacheKey,
    queryFunction(
      person_api.getPerson(api),
      ({ queryKey }) => parsePersonKey(queryKey).personID ?? ''
    ),
    {
      enabled: state.user.token.length > 0 && !!personID,
    }
  );
}
