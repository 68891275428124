import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import {
  magicTokenKeys,
  parseMagicTokenKey,
} from '../cache-keys/magicTokenKeys';
import { loan_application_api } from '@axo/shared/data-access/api';
import {
  applicationKeys,
  parseApplicationKey,
} from '../cache-keys/applicationKeys';
import { useContext } from 'react';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { queryFunction } from '../query-function';
import {
  LoanApplicationByLegacyIdKeys,
  parseLoanApplicationByLegacyIdKey
} from '../cache-keys/loanApplicationLegacyIdKeys.tsx';

function magicLoanApplicationQuery({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof magicTokenKeys)['magicLoanApplication']>
>) {
  const params = parseMagicTokenKey(queryKey);
  return loan_application_api.getLoanApplicationFromMagicToken(
    params.baseURL,
    params.magicToken
  );
}

export function useLoanApplicationFromMagicToken(
  baseURL: string,
  magicToken: string | null
) {
  const cacheKey = magicTokenKeys.magicLoanApplication({
    baseURL,
    magicToken,
  });

  return useQuery(cacheKey, magicLoanApplicationQuery, {
    enabled: !!magicToken,
  });
}

export function useLoanApplication(id: string | undefined | null) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();
  const cacheKey = applicationKeys.root({
    applicationID: id ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(
      loan_application_api.getLoanApplication(api),
      ({ queryKey }) => parseApplicationKey(queryKey).applicationID ?? ''
    ),
    {
      enabled: !!state.user.token && !!id,
    }
  );
}

export function useLoanApplicationFromLegacyId({
  legacyID,
  customerID
}: { legacyID: string, customerID: string | null }) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();
  const cacheKey = LoanApplicationByLegacyIdKeys.root({baseURL: api.url.service, legacyID: legacyID, customerID: customerID});
  const client = useQueryClient();

  return useQuery(
    cacheKey,
    queryFunction(
      loan_application_api.getLoanApplicationFromLegacyId(api),
      ({ queryKey }) => ({legacyID: parseLoanApplicationByLegacyIdKey(queryKey).legacyID, customerID: parseLoanApplicationByLegacyIdKey(queryKey).customerID})
    ),
    {
      enabled: !!state.user.token && !!legacyID && !!customerID,
      onError: () => client.invalidateQueries(cacheKey)
    }
  );
}
