/// <reference types="./@types/gtm" />
export function gtag(..._args: unknown[]) {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

type GtagIdentifyArgs = {
  user_id: string;
  email?: string;
  phonenumber?: string;
};
export function gtagIdentify(data: GtagIdentifyArgs) {
  window.dataLayer.push(data);
}
