import { API, APIError, APIHeaders, APIRequest, APIResponse } from './api';

export function fetchAPI(defaultHeaders: APIHeaders): API['send'] {
  return async <R, B = unknown>(
    req: APIRequest<B>
  ): Promise<APIResponse<R>> => {
    const res = await fetch(req.url, {
      method: req.method?.toUpperCase() ?? 'GET',
      mode: 'cors',
      headers: {
        ...defaultHeaders,
        ...(req.headers ?? {}),
        ...(req.data ? { 'Content-Type': 'application/json' } : null),
      },
      body: req.data ? JSON.stringify(req.data) : undefined,
    });

    const headers = Object.keys(res.headers).reduce((acc, key) => {
      acc[key] = res.headers.get(key) ?? '';
      return acc;
    }, {} as APIHeaders);

    const status = res.status;

    if (!res.ok) {
      throw new APIError(`Request failed with status ${status}`, res);
    }

    return {
      status,
      headers,
      data: await res.json(),
    };
  };
}
