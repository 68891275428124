/* eslint-disable axo-frontend/directory-structure */
import { A, H5, Hr, Icons, P } from '@axo/deprecated/util/ui-components';
import {
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '@axo/insurance/const/fi';
import { StepContext } from '@axo/insurance/feature/providers';
import { NavWrapper, StepNavigator } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useTranslation } from '@axo/shared/i18n';
import { useAnalytics } from '@axo/shared/services/analytics';
import { classNames } from '@axo/shared/util/dom';
import { currencyFormatter } from '@axo/shared/util/formatters';
import { useContext } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import styles from './Summary.module.scss';

export const Summary = () => {
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { state, onEnd, isEmbedded } = useContext(StepContext);
  const { t } = useTranslation();
  const log = useEventLogger();
  const { track } = useAnalytics();
  useEventField('current-step', 'summary');

  async function handleNext() {
    const { insurance } = state.steps.selectCoverage;
    log(EventCode.InsuranceNext, {
      payout: insurance.payout.toString(),
      premium: insurance.premium.toString(),
    });

    await track({
      event: 'Insurance Step summary Completed',
      options: {
        send_immediately: true,
      },
    });

    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  return (
    <>
      <div className={classNames(styles.contentMargin)}>
        <div className={styles.shield}>
          <Icons.Shield size="md" />
          <h3 className={styles.thankYou}>{t('Thank you')}!</h3>
        </div>
        {t('Congratulations with your insurance')}
        <div className={styles.infoBox}>
          <div className={styles.infoHeader}>
            <H5>{t('Insurance information')}</H5>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.infoItem}>
            <span className={styles.value}>
              {t('Will be sent to you by email')}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.infoItem}>
            <P className={styles.label}>
              {t('Payable in the event of damage')}
            </P>
            <span className={styles.value}>
              {currencyFormatter(state.steps.selectCoverage.insurance.payout)}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.infoItem}>
            <P className={styles.label}>{t('Monthly cost')}</P>
            <span className={styles.value}>
              {currencyFormatter(state.steps.selectCoverage.insurance?.premium)}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.extraInfo}>
            <p className={styles.label}>
              {t('Detailed information on insurance')}
            </p>
            <div className={styles.extraInfoLinkContainer}>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A href={productFactSheetUrl} target="_blank" rel="noreferrer">
                  {t('Product Fact Sheet')}
                </A>
              </div>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A
                  href={prePurchaseInformationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms and Conditions')}
                </A>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          rightButton={{
            width: 'full-width',
            text: onEnd.summaryButtonText,
            variant: 'enabled',
            onClick: handleNext,
          }}
        />
      </NavWrapper>
    </>
  );
};
