import { Config } from 'mixpanel-browser';

export const getMixpanelConfig = (debug = false): Partial<Config> => ({
  debug,
  track_pageview: true,
  persistence: 'localStorage',
  // persistence: 'cookie',
  cross_subdomain_cookie: true,
  // whether to ignore or respect the web browser's Do Not Track setting
  // (default is false)
  // ignore_dnt: true,
  loaded: (mixpanel) => {
    // console.log('Mixpanel loaded', mixpanel);
  },
});
