import { ReactNode } from 'react';
import styles from './InputStateBox.module.scss';
import { classNames } from '@axo/shared/util/dom';
import { IInputPrimaryState } from '../../models/input.model';
import { Icons, LoadingSpinner } from '@axo/deprecated/util/ui-components';

interface IconProps {
  primaryState?: IInputPrimaryState;
  icon?: ReactNode;
}

const Icon = ({ primaryState, icon }: IconProps) => {
  if (primaryState === 'valid') {
    return <Icons.CircleCheckMarkValid />;
  }
  if (primaryState === 'loading') {
    return <LoadingSpinner colorVariable={'--dark-200'} />;
  }
  if (primaryState === 'invalid') {
    return <Icons.CircleWarningWhite color={{ primary: '--secondary-700' }} />;
  }
  return <>{icon}</>;
};

enum ClassNames {
  Root = 'root',
  IconWrapper = 'iconWrapper',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IInputStateBox {
  hasFocus?: boolean;
  primaryState?: IInputPrimaryState;
  children: ReactNode;
  icon?: ReactNode;
  classes?: ClassNamesProp;
}

export const InputStateBox = ({
  hasFocus,
  primaryState,
  icon,
  children,
  classes,
}: IInputStateBox) => {
  return (
    <div
      className={classNames(
        styles.root,
        primaryState && styles[primaryState],
        hasFocus ? styles.focused : styles.unfocused,
        classes?.root
      )}
    >
      {children}
      <div className={classNames(styles.iconWrapper, classes?.iconWrapper)}>
        <Icon primaryState={primaryState} icon={icon} />
      </div>
    </div>
  );
};

export default InputStateBox;
