import { createContext } from 'react';
import { InsuranceAction } from './actions';
import { InsuranceState, insuranceInitialState } from './reducer';

export const InsuranceContext = createContext<{
  state: InsuranceState;
  dispatch: React.Dispatch<InsuranceAction>;
}>({
  state: insuranceInitialState,
  dispatch: () => undefined,
});
