import {
  InsuranceState,
  StepperState,
  insuranceInitialState,
} from '@axo/insurance/feature/providers';
import {
  DataAccessState,
  initialState as dataAccessInitialState,
} from '@axo/shared/data-access/provider';
import {
  LoanApplicationState,
  loanApplicationInitialState,
} from '@axo/shared/feature/providers';
import { URLSearchParams } from 'url';
import { getInitialState } from '../../CardMandateProvider/store/initialState';
import { getInitialStepState } from '../../StepProvider';

export const stateQueryString = 'state';

function crateStateKey() {
  if (window.location.protocol === 'https' && 'crypto' in window) {
    return window.crypto.randomUUID();
  }

  return (Math.random() + 1).toString(36).substring(2);
}

export function getInitialDataAccessContextState(search: URLSearchParams) {
  const state = search.get(stateQueryString);

  if (!state) return dataAccessInitialState;

  const item = sessionStorage.getItem(state);

  if (!item) return dataAccessInitialState;

  return JSON.parse(item).dataAccessContext as DataAccessState;
}

export function getInitialStepContextState(search: URLSearchParams) {
  const state = search.get(stateQueryString);

  if (!state) return getInitialStepState();

  const item = sessionStorage.getItem(state);

  if (!item) return getInitialStepState();

  return JSON.parse(item).stepContext as StepperState;
}

export function getInitialCardMandateState(search: URLSearchParams) {
  const state = search.get(stateQueryString);

  if (!state) return getInitialState();

  const item = sessionStorage.getItem(state);

  if (!item) return getInitialState();

  return JSON.parse(item).stepContext as StepperState;
}

export function getInitialAppContextState(
  search: URLSearchParams,
  initialData?: Partial<LoanApplicationState>
) {
  const state = search.get(stateQueryString);

  if (!state) return { ...loanApplicationInitialState, ...initialData };

  const item = sessionStorage.getItem(state);

  if (!item) return { ...loanApplicationInitialState, ...initialData };

  return JSON.parse(item).applicationContext as LoanApplicationState;
}

export function getInitialInsuranceContextState(
  search: URLSearchParams,
  initialData?: Partial<InsuranceState>
) {
  const state = search.get(stateQueryString);

  if (!state) return { ...insuranceInitialState, ...initialData };

  const item = sessionStorage.getItem(state);

  if (!item) return { ...insuranceInitialState, ...initialData };

  return JSON.parse(item).insuranceContext as InsuranceState;
}

export interface IStoredState {
  dataAccessContext: DataAccessState;
  applicationContext: LoanApplicationState;
  insuranceContext: InsuranceState;
  stepContext: StepperState;
}

export function create() {
  const key = crateStateKey();
  const url = new URL(window.location.href);
  url.searchParams.set(stateQueryString, key);

  return { url: url.toString(), key };
}

export function get(url: URL) {
  const key = url.searchParams.get(stateQueryString);

  if (!key) throw new Error(`Missing state param`);

  return { url: url.toString(), key };
}

export function store<T extends object>(key: string, state: T) {
  sessionStorage.setItem(key, JSON.stringify(state));
}
