export enum Status {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
}

export interface Payment {
  ID: string;
  InsuranceID: string;
  CustomerID: string;
  MarketCountry: string;
  CompleteURL?: string;
  PaymentURL?: string;
  UserAgent: string;
  Amount: number;
  Status: Status;
  CreatedAt: string;
  UpdatedAt: string;
  OrderID: string;
  EmbedURL: string;
  CardNumberTruncated: string;
  CardExpiryMonth: number;
  CardExpiryYear: number;
  RegistrationNumber?: string;
  AccountNumber?: string;
}

export type CreatePaymentRequest = Pick<
  Payment,
  | 'InsuranceID'
  | 'CustomerID'
  | 'MarketCountry'
  | 'PaymentURL'
  | 'CompleteURL'
  | 'UserAgent'
  | 'Amount'
>;

export type CreatePaymentMandateRequest = Pick<
  Payment,
  'InsuranceID' | 'CustomerID' | 'MarketCountry' | 'CompleteURL' | 'Amount'
>;

export type CreateBsPaymentMandateRequest = Pick<
  Payment,
  'InsuranceID' | 'CustomerID' | 'MarketCountry' | 'Amount' | 'RegistrationNumber' | 'AccountNumber'
>;

export interface FindPaymentInput {
  insuranceID?: string;
  marketCountry?: string;
  customerID?: string;
}

export interface FindPaymentResponse {
  Items: Payment[];
}
