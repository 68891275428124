import mixpanel from 'mixpanel-browser';
import {
  AnalyticsEvent,
  AnalyticsExperiment,
  AnalyticsIdentify,
  IAnalytics,
} from '../Analytics.types';
import {
  IAnalyticsService,
  IAnalyticsServiceParams,
} from '../AnalyticsService.types';
import { timeoutAfter } from '../utils/timeoutAfter';
import { waitUntil } from '../utils/waitUntil';

/**
 * Mixpanel Service
 *
 * @see https://developer.mixpanel.com/docs/javascript
 */
export const MixpanelService: IAnalyticsService = (
  params: IAnalyticsServiceParams = {}
): IAnalytics => {
  const { timeout = 2000 } = params;
  const hasServiceConfigured = !!mixpanel.get_config('token');

  if (!hasServiceConfigured) {
    console.log('MixpanelService is not configured');
  }

  const waitForInitialization = () => waitUntil(isInitialized);
  const isInitialized = () => !!mixpanel.get_config('token');

  const track = async (e: AnalyticsEvent) => {
    if (!hasServiceConfigured)
      return Promise.reject(
        new Error('MixpanelService service is not present')
      );

    const promise = new Promise<void>((resolve) => {
      if (e.options?.send_immediately) {
        mixpanel.track(e.event, e.params, e.options, () => resolve());
      } else {
        mixpanel.track(e.event, e.params, e.options);
        resolve();
      }
    });

    return timeoutAfter(promise, timeout, 'MixpanelService timed out');
  };

  /**
   * Identify a user by their unique ID.
   *
   * @see https://docs.mixpanel.com/docs/tracking-methods/id-management/identifying-users
   */
  const identify = async ({ uuid, email }: AnalyticsIdentify) => {
    if (!hasServiceConfigured)
      return Promise.reject(
        new Error('MixpanelService service is not present')
      );

    mixpanel.identify(uuid);
    email && mixpanel.people.set({ $email: email });
  };

  const reset = () => {
    mixpanel.reset();
  };

  /**
   *
   * https://docs.mixpanel.com/docs/tracking-methods/integrations/launchdarkly
   */

  const trackExperiment = async (exp: AnalyticsExperiment) => {
    return track({
      // track as regular event, the '$track_experiment' is on a higher paid plan on mixpanel
      // event: '$track_experiment' as unknown as Events,
      event: 'Track Experiment',
      params: {
        'Experiment name': exp.name,
        'Variant name': exp.variation,
      },
      options: {
        send_immediately: true,
      },
    });
  };

  return {
    waitForInitialization,
    isInitialized,
    track,
    identify,
    reset,
    trackExperiment,
  };
};
