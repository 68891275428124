/* eslint-disable axo-frontend/directory-structure */
import { InsuranceContext } from '@axo/insurance/feature/providers';
import { PageSpinner } from '@axo/insurance/ui';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { insurance, insurance_request } from '@axo/shared/data-access/types';
import { ReactNode, useContext, useEffect } from 'react';
import { OnEnd } from './InsuranceProvider';
import { useProvideInsurance } from './useProvideInsurance';

export type InsuranceContentProps = {
  children: ReactNode;
  onEnd: OnEnd;
  spinner?: ReactNode;
  sourceTag: insurance.InsuranceSourceTag;
};

export function InsuranceContent({
  children,
  onEnd,
  spinner = <PageSpinner />,
  sourceTag,
}: InsuranceContentProps) {
  const { createInsuranceRequest, fetchInsuranceRequest, hasTimedOut } =
    useProvideInsurance({ sourceTag: sourceTag });
  const {
    state: { insurancePolicyReference: insurancePolicyID, insurance },
  } = useContext(InsuranceContext);
  const { state: authState } = useContext(DataAccessContext);

  useEffect(() => {
    // Ineligible or already signed.
    if (
      createInsuranceRequest.error ||
      (fetchInsuranceRequest.data?.Status === insurance_request.Status.Denied &&
        !fetchInsuranceRequest.data?.RetryPossible)
    ) {
      const token = authState.user.token;
      const uid = insurance?.ID;
      onEnd.action({ token, uid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createInsuranceRequest.error,
    onEnd,
    fetchInsuranceRequest.data?.RetryPossible,
    fetchInsuranceRequest.data?.Status,
  ]);

  if (hasTimedOut) {
    const token = authState.user.token;
    const uid = insurance?.ID;
    onEnd.action({ token, uid });
    return <>{spinner}</>;
  }

  // Pending decision: approved or denied.
  if (
    createInsuranceRequest.isLoading ||
    fetchInsuranceRequest.isLoading ||
    fetchInsuranceRequest.data?.Status === insurance_request.Status.New
  ) {
    return <>{spinner}</>;
  }

  if (!insurancePolicyID) {
    return <>{spinner}</>;
  }

  return <>{children}</>;
}
