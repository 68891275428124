import { loan_application, StatusGroup } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';
import { send } from '../../lib/send';

export function getLoanApplication(api: IAPI) {
  return async function getLoanApplication(
    id: string
  ): Promise<loan_application.LoanApplication> {
    return send({
      baseURL: api.url.service,
      path: `/loan-application/${id}`,
      token: api.token,
      method: 'GET',
    });
  };
}

export interface MagicOutput extends loan_application.LoanApplication {
  AcceptTermsConditions: boolean;
  AppliedAmount: number;
  Citizenship: string;
  CreatedAt: string;
  CustomerID: string;
  EmailReminders: boolean;
  HumanReadableID: string;
  ID: string;
  IP: string;
  JWT: string;
  LoanDuration: number;
  MagicToken: string;
  MarketCountry: string;
  SMSReminders: boolean;
  TermsAndConditionsText: string;
  UpdatedAt: string;
  WaitingForBanks: boolean;
}

export const getLoanApplicationFromMagicToken = async (
  baseURL: string,
  magicToken: string | null
): Promise<MagicOutput> =>
  send({
    baseURL,
    path: `/loan-application/magic/${magicToken}`,
    method: 'GET',
  });

export function getLoanApplicationFromLegacyId(api: IAPI) {
  return async function getLoanApplication({
    legacyID,
    customerID,
  }: {
    legacyID: string | null;
    customerID: string | null;
  }): Promise<loan_application.LoanApplication> {
    const loanApplications = (await send({
      baseURL: api.url.service,
      path: `loan-application?legacyID=${legacyID}&customerID=${customerID}`,
      token: api.token,
      method: 'GET',
    })) as loan_application.LoanApplication[];
    return loanApplications[0];
  };
}

export function postLoanApplication({
  url: { service: baseURL },
  token,
}: IAPI) {
  return (
    loanApplication: Partial<loan_application.LoanApplication>
  ): Promise<loan_application.LoanApplication> =>
    send({
      baseURL,
      path: `/loan-application`,
      token,
      method: 'POST',
      body: loanApplication,
    });
}

export interface LoanApplicationPatchError {
  applicationIDs: string[];
  message: string;
  code: number;
}

export function patchLoanApplication({
  url: { service: baseURL },
  token,
}: IAPI) {
  return async (
    id: string,
    loanApplication: loan_application.UpdateLoanApplication
  ): Promise<loan_application.LoanApplication> => {
    const response = await fetch(`${baseURL}/loan-application/${id}`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(loanApplication),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    if (response.status === 403 && responseJson.applicationIDs) {
      throw responseJson as LoanApplicationPatchError;
    }

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}

export function archiveLoanApplication({
  url: { service: baseURL },
  token,
}: IAPI) {
  return (
    id: string,
    archiveRequestType: loan_application.ArchiveRequestType
  ): Promise<loan_application.LoanApplication> =>
    send({
      baseURL,
      path: `/loan-application/${id}/archive`,
      token,
      method: 'POST',
      body: { ArchiveRequestType: archiveRequestType },
    });
}

export type LoanApplicationByCustomerIDParams = {
  customerId: string;
  pagination: { offset: number; limit: number };
  groups: StatusGroup[];
};

export function findLoanApplicationByCustomerID(api: IAPI) {
  return async function findLoanApplicationByCustomerID({
    customerId,
    pagination = { offset: 0, limit: 100 },
    groups = [],
  }: LoanApplicationByCustomerIDParams): Promise<
    loan_application.LoanApplication[]
  > {
    const groupParams = groups
      ? groups.map((group) => `&group=${encodeURIComponent(group)}`).join('')
      : '';

    return send({
      baseURL: api.url.service,
      path: `/loan-application?customerID=${customerId}&pageSize=${
        pagination.limit
      }&pageNumber=${pagination.offset + 1}${groupParams}`,
      token: api.token,
      method: 'GET',
    });
  };
}

export type LoanApplicationByPersonIDParams = {
  personID: string;
  pagination: { offset: number; limit: number };
  groups: StatusGroup[];
};

export function findLoanApplicationByPersonID(api: IAPI) {
  return async function findLoanApplicationByPersonID({
    personID,
    pagination = { offset: 0, limit: 100 },
    groups = [],
  }: LoanApplicationByPersonIDParams): Promise<
    loan_application.LoanApplication[]
  > {
    const groupParams = groups
      ? groups.map((group) => `&group=${encodeURIComponent(group)}`).join('')
      : '';

    return send({
      baseURL: api.url.service,
      path: `/loan-application?personID=${personID}&pageSize=${
        pagination.limit
      }&pageNumber=${pagination.offset + 1}${groupParams}`,
      token: api.token,
      method: 'GET',
    });
  };
}
