import { FC } from 'react';
import useGeneratedId from '../../../hooks/useGeneratedId';
import { ICSSColorVariable } from '../../../models/color.model';
export interface ICircularProgress {
  from?: number;
  to: number;
  steps: number;
  color: ICSSColorVariable;
  startCapColor?: ICSSColorVariable;
  endCapColor?: ICSSColorVariable;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const CircularProgress: FC<ICircularProgress> = ({
  from = 0,
  to = 0,
  steps = 5,
  color = '--light-700',
  startCapColor = color,
  endCapColor = color,
}) => {
  if (to > steps) to = steps;
  else if (to < 0) to = 0;
  if (from > to) from = to;
  else if (from < 0) from = 0;

  const paddingInRadians = 0.01;
  const startRadians =
    (from / steps) * 2 * Math.PI - Math.PI / 2 + paddingInRadians;
  const endRadians =
    (to / steps) * 2 * Math.PI - Math.PI / 2 - paddingInRadians;
  const width = 1;
  const hasCompleted = to - from === steps;
  const { x: x0, y: y0 } = fromRadiansToXY(startRadians);
  const { x: x1, y: y1 } = fromRadiansToXY(endRadians);
  const useLongPath = to > steps / 2 ? 1 : 0;

  const id = useGeneratedId();

  return (
    <svg width="300" height="300" viewBox="0 0 10 10" version="1.1">
      {/* The mask hides the center of the circle, making the circle/circle sector below into a thick line */}
      <mask id={id}>
        <circle cx="5" cy="5" r="5" fill="white" />
        <circle cx="5" cy="5" r={5 - width} fill="black" />
      </mask>
      {!hasCompleted ? (
        <>
          {/* The progress bar itself */}
          <path
            d={`M 5 5 L ${x0} ${y0} A 5 5 0 ${useLongPath} 1 ${x1} ${y1} Z`}
            fill={`var(${color})`}
            mask={`url(#${id})`}
          />
          {/* Draw lines at the ends of the progress bar */}
          <LineCap
            radians={startRadians + paddingInRadians}
            length={width}
            color={startCapColor}
          />
          <LineCap
            radians={endRadians - paddingInRadians}
            length={width}
            color={endCapColor}
          />
        </>
      ) : (
        // If the progress bar is full, render a complete circle without linecaps
        <circle
          cx="5"
          cy="5"
          r="5"
          fill={`var(${color})`}
          mask={`url(#${id})`}
        />
      )}
    </svg>
  );
};

const LineCap: FC<{
  radians: number;
  length: number;
  color: ICSSColorVariable;
}> = ({ radians, length, color }) => {
  const { x, y } = fromRadiansToXY(radians);
  return (
    <path
      d={`M ${x} ${y} L ${x - ((x - 5) / 5) * length} ${
        y - ((y - 5) / 5) * length
      }`}
      stroke={`var(${color})`}
      strokeWidth="0.2"
    />
  );
};

const fromRadiansToXY = (radians: number): { x: number; y: number } => {
  return {
    x: 5 + Math.cos(radians) * 5,
    y: 5 + Math.sin(radians) * 5,
  };
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default CircularProgress;
