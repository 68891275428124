import { permissions } from '../index';

export interface Resource {
  Type: string;
  ID: string;
}

export interface Field {
  Key: string;
  Value: string;
}

export enum Medium {
  Customer = 'Customer',
  EventBridge = 'EventBridge',
  Person = 'Person',
  Client = 'Client',
}

export interface EventBridgeContext {
  RequestID: string;
  MessageID: string;
}

export interface CustomerContext {
  IP: string;
  UserAgent: string;
}

export interface OriginIdentity {
  ClientID?: string;
  CustomerID?: string;
  PersonID?: string;
  Roles: permissions.Role[];
}

export interface Event {
  ID: string;
  CreatedAt: string;
  MarketCountry: string;
  Medium: Medium;
  Source: string;
  EventCode: string;
  Resources: Resource[];
  Fields: Field[];
  EventBridgeContext?: EventBridgeContext;
  CustomerContext?: CustomerContext;
  Origin: OriginIdentity;
}

export interface ApplicationContext {
  CustomerID?: string;
  PersonID?: string;
  LoanApplicationID?: string;
}

export interface Find {
  ApplicationContext?: ApplicationContext;

  ResourceType?: string;
  ResourceID?: string;

  FieldKey?: string;
  FieldValue?: string;

  MarketCountry?: string;
  Medium?: Medium;
  Source?: string;
  EventCode?: string;

  EventBridgeContext?: EventBridgeContext;
  CustomerContext?: CustomerContext;

  Before?: string;

  Limit?: number;
}

export interface FindEventsResponse {
  Items: Event[];
  Limit: number;
  NextCursor?: string;
}
