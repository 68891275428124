import { A, Checkbox, KeyBenefits } from '@axo/deprecated/util/ui-components';
import { prePurchaseInformationAndTermsAndConditionsUrl } from '@axo/insurance/const/fi';
import { StepContext } from '@axo/insurance/feature/providers';
import {
  ContentBoxWrapper,
  NavWrapper,
  StepNavigator,
  ValidationContentBox,
} from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { useTranslation } from '@axo/shared/i18n';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { getValidState } from '../../getValidState';
import styles from './Terms.module.scss';

export const Terms = () => {
  const { dispatch, state, isEmbedded } = useContext(StepContext);
  const { t } = useTranslation();
  const log = useEventLogger();
  const ref = useRef<HTMLDivElement>(null);
  useEventField('current-step', 'terms');
  const { track } = useAnalytics();

  const {
    steps: { terms: stepData },
  } = state;

  const [triedContinue, setTriedContinue] = useState(false);

  function handleNext() {
    setTriedContinue(true);
    if (stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms) {
      log(EventCode.InsuranceNext);
      dispatch({
        type: 'Set step data',
        scope: { parentType: stepData.name },
        payload: { ...stepData, state: 'completed' },
      });
      track({
        event: `Insurance Step ${stepData.name} Completed`,
      });
      dispatch({
        type: 'Set step',
        scope: { parentType: 'step' },
        payload: 'powerOfAttorney',
      });
    } else {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function handleBack() {
    log(EventCode.InsuranceBack);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'selectCoverage',
    });
  }

  const enableNextButton =
    stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms;

  return (
    <>
      <ContentBoxWrapper isEmbedded={isEmbedded}>
        <div className={styles.content}>
          <div className={styles.keyBenefits}>
            <KeyBenefits
              benefits={[
                t('18-65 years old'),
                t('I live permanently in Finland'),
                t(
                  'I am on a permanent employment relationship and work at least 16 hours a week'
                ),
                t(
                  'I am 100 % capable and I am not aware of future lay-off or dismissal'
                ),
                t('I am not aware of any illness or chronic condition'),
              ]}
            />
          </div>
          <ValidationContentBox
            active={
              stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms
            }
            invalid={
              triedContinue &&
              !(stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms)
            }
          >
            <div ref={ref}>
              <Checkbox
                name="hasConfirmedEligibility"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: { hasConfirmedEligibility: e.target.checked },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedEligibility,
                  triedContinue,
                  t('To proceed, you must be eligible')
                )}
                classes={{ root: styles.checkbox }}
                value={stepData.hasConfirmedEligibility}
              >
                <Trans
                  i18nKey="I will meet the above conditions"
                  ns="Insurance"
                >
                  I will meet the above conditions and confirm that I have read
                  and agree to
                  <A
                    href={prePurchaseInformationAndTermsAndConditionsUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    pre-purchase information and complete insurance terms and
                    conditions
                  </A>
                </Trans>
              </Checkbox>
              <Checkbox
                name="hasConfirmedTerms"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: { hasConfirmedTerms: e.target.checked },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedTerms,
                  triedContinue,
                  t('To proceed, you must accept the terms')
                )}
                value={stepData.hasConfirmedTerms}
              >
                {t('I understand that security insurance is valid despite')}
              </Checkbox>
            </div>
          </ValidationContentBox>
        </div>
      </ContentBoxWrapper>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          leftButton={{
            width: 'full-width',
            text: t('Go back'),
            variant: 'back',
            onClick: () => handleBack(),
          }}
          rightButton={{
            width: 'full-width',
            text: t('Next'),
            variant: enableNextButton ? 'enabled' : 'disabled',
            onClick: () => handleNext(),
          }}
        />
      </NavWrapper>
    </>
  );
};
