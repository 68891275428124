import { insurance, insurance_request } from '@axo/shared/data-access/types';
import { InsuranceAction } from './actions';

export type InsurancePolicyReference = insurance_request.Request;
export type Insurance = insurance.InsurancePolicy;

export type InsuranceState = {
  error: { error: Error; showHelp?: boolean } | undefined;
  insurancePolicyReference?: InsurancePolicyReference;
  insurance?: Insurance;
  source?: insurance.InsuranceSourceTag;
};

export const insuranceInitialState: InsuranceState = {
  error: undefined,
  source: undefined,
};

export function reducer(
  state: InsuranceState,
  action: InsuranceAction
): InsuranceState {
  switch (action.type) {
    case 'Set error':
      return { ...state, error: action.payload };
    case 'Set insurance':
      return { ...state, insurancePolicyReference: action.payload };
    case 'Set insurance policy':
      return { ...state, insurance: action.payload };
    case 'Set source':
      return { ...state, source: action.payload };
    default:
      return state;
  }
}
