import {
  useTranslation as _useTranslation,
  type UseTranslationOptions,
} from 'react-i18next';
import { useTranslationContext } from './context';

export function useTranslation(
  ns?: string,
  options?: UseTranslationOptions<never>
) {
  const { ns: providerNamespace = '' } = useTranslationContext();

  const _namespace = ns || providerNamespace;

  return _useTranslation(_namespace, options);
}
