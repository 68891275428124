import { FC, ReactNode } from 'react';
import { Icons } from '../../atoms/Icons';
import { H4 } from '../../atoms/StyledElements';
import { IStepper, IStepperStepState } from '../Stepper';
import styles from './StepperDesktop.module.scss';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const StepperDesktop: FC<IStepper> = ({
  steps,
  hideHeadline = false,
}) => {
  const title = steps.filter((step) => step.state === 'active')[0]?.title;

  return (
    <>
      <div className={styles.root}>
        {steps.map((step, index) => {
          return (
            <Step
              key={step.name}
              label={step.title ?? step.name}
              number={index + 1}
              variant={step.state}
              withSeparator={index !== 0}
            />
          );
        })}
      </div>
      {title && !hideHeadline && <H4 className={styles.title}>{title}</H4>}
    </>
  );
};

const Step: FC<{
  number: number;
  label: ReactNode;
  variant: IStepperStepState;
  withSeparator: boolean;
}> = ({ number, label, variant, withSeparator }) => {
  return (
    <>
      {withSeparator && <div className={styles.stepSeparator} />}
      <div className={styles.step}>
        <div className={`${styles.stepIcon} ${styles[variant]}`}>
          {variant === 'completed' ? (
            <Icons.CheckMarkWhite size="xs" />
          ) : (
            number
          )}
        </div>

        <div className={styles.stepLabel}>{label}</div>
      </div>
    </>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default StepperDesktop;
