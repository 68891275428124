/**
 * Takes a function and returns a new throttled function, meaning that if the
 * function is called multiple times in a row, before a certain delay has been
 * reached, only the most recent call to the function will be executed. Note
 * that it is the function returned that is throtteled, so we need to make sure
 * the function is not recreated every time it is used. For example, this
 * function should be used together with useMemo if used in a component. Since
 * the execution of the function will be after the delay, it returns a Promise
 * which resolves to the return value of the original pre-throtteled function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const throttleFunction = <Args extends any[], T>(
  callback: (...args: Args) => T,
  throttleForMs = 500
): ((...args: Args) => Promise<T>) => {
  let timeout: NodeJS.Timeout;
  const fn = callback;

  return async (...args: Args) =>
    new Promise((resolve) => {
      if (timeout) clearTimeout(timeout);

      const currentTimeout = setTimeout(() => {
        if (currentTimeout === timeout) resolve(fn(...args));
      }, throttleForMs);

      timeout = currentTimeout;
    });
};
