import { FC } from 'react';

import styles from './Label.module.scss';
import clsx from 'clsx';

export interface ILabel extends React.HTMLProps<HTMLLabelElement> {
  labelText?: string;
  display?: 'inline' | 'block' | 'inline-block';
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Label: FC<ILabel> = ({
  children,
  labelText,
  className,
  ...props
}: ILabel) => {
  return (
    <label
      {...props}
      className={clsx('Label', styles['label-wrapper'], className)}
    >
      {labelText && (
        <div className={clsx('Label__labelText', styles.labelText)}>
          {labelText}
        </div>
      )}
      {children}
    </label>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Label;
