export interface IMagicTokenKeyParams {
  baseURL: string;
  magicToken: string | null;
}

export const magicTokenKeys = {
  root: (params: IMagicTokenKeyParams) =>
    ['baseURL', params.baseURL, 'magicToken', params.magicToken] as const,
  magicLoanApplication: (params: IMagicTokenKeyParams) =>
    [...magicTokenKeys.root(params), 'loanApplication'] as const,
};

export function parseMagicTokenKey<T extends keyof typeof magicTokenKeys>(
  queryKey: ReturnType<(typeof magicTokenKeys)[T]>
) {
  const [, baseURL, , magicToken] = queryKey;

  return { baseURL, magicToken };
}
