import { isBrowser } from '@axo/shared/util/dom';
import { CrossDomainAuthData } from '../CrossDomainAuth.types';
import { StorageKeys } from '../StorageKeys';

export const storeCrossDomainAuth = (data: CrossDomainAuthData): void => {
  if (!isBrowser()) return;

  if (data.applicationId !== undefined)
    sessionStorage.setItem(StorageKeys.AppId, data.applicationId);

  if (data.token !== undefined)
    sessionStorage.setItem(StorageKeys.Token, data.token);
};
