import { createContext } from 'react';
import { event_log } from '@axo/shared/data-access/types';

export interface IBreadcrumbContext {
  getResources(): event_log.Resource[];

  getFields(): event_log.Field[];

  addResource(resource: event_log.Resource): () => void;

  addField(resource: event_log.Field): () => void;
}

export const BreadcrumbContext = createContext<IBreadcrumbContext | null>(null);
