import { FC, useEffect, useState } from 'react';
import { ICSSColorVariable } from '../../../models/color.model';
import styles from './LoadingSpinner.module.scss';

export interface ILoadingSpinner {
  colorVariable: ICSSColorVariable;
}
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const LoadingSpinner: FC<ILoadingSpinner> = ({ colorVariable }) => {
  const circles = 16,
    circleRadius = 7,
    loaderRadius = 43,
    stageSize = 100;

  const [opacityOffset, setCounter] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => setCounter((c) => ++c), 100);
    return () => clearInterval(interval);
  });

  return (
    <svg
      width="35"
      height="35"
      viewBox={`0 0 ${stageSize} ${stageSize}`}
      version="1.1"
      className={styles.spinner}
    >
      {new Array(circles).fill(0).map((_, i) => (
        <circle
          key={i}
          cx={
            stageSize / 2 +
            loaderRadius * Math.cos(((2 * Math.PI) / circles) * i)
          }
          cy={
            stageSize / 2 +
            loaderRadius * Math.sin(((2 * Math.PI) / circles) * i)
          }
          r={circleRadius}
          fill={`var(${colorVariable})`}
          fillOpacity={1 - (((circles - i + opacityOffset) / circles) % 1)}
          data-i={i}
        />
      ))}
    </svg>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default LoadingSpinner;
