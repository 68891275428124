import { createContext, useContext } from 'react';
import { Locales } from './types';

export type ITranslationContext = {
  language: Locales;
  ns?: string;
};

export const TranslationContext = createContext<ITranslationContext>({
  language: 'en',
});

export const useTranslationContext = () => useContext(TranslationContext);
