import { IPayOutAndPremium, payoutAndPremium } from '@axo/insurance/const/fi';
import {
  StepperState,
  getInitialStepperState,
} from '@axo/insurance/feature/providers';
import { i18n } from '@axo/shared/i18n';

// Ideally this should not be in state at all, but instead in relevant components.
const t = i18n.getFixedT('fi', 'Insurance');

function findCoverage(payout: IPayOutAndPremium['payout']): IPayOutAndPremium {
  const coverage = payoutAndPremium.find((x) => x.payout === payout);
  if (!coverage) return { payout: 0, premium: 0 };
  return coverage;
}

export const getInitialStepState = (): StepperState => getInitialStepperState({
  error: undefined,
  selectedStep: 'explainer',
  steps: {
    explainer: {
      name: 'explainer',
      title: t('Secure your economy'),
      state: 'active',
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    selectCoverage: {
      name: 'selectCoverage',
      title: t('Your insurance'),
      state: 'untouched',
      insurance: findCoverage(0),
      personData: {
        address: {
          City: '',
          Street: '',
          ZipCode: '',
        },
        name: {
          FirstName: '',
          LastName: '',
        },
      },
      isFormShown: {
        address: false,
        name: false,
      },
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    terms: {
      name: 'terms',
      title: t('Terms'),
      state: 'untouched',
      hasConfirmedEligibility: false,
      hasConfirmedTerms: false,
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    powerOfAttorney: {
      name: 'powerOfAttorney',
      title: t('TermsOfPayment'),
      state: 'untouched',
      hasConfirmedPowerOfAttorney: false,
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    verifyPaymentCard: {
      name: 'verifyPaymentCard',
      title: t('Accept and pay'),
      state: 'untouched',
      payment: null,
      paymentStateKey: null,
      completeStateKey: null,
      isCompleted: false,
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    summary: {
      name: 'summary',
      title: t('Thank you'),
      state: 'untouched',
      isShownInStepper: false,
      isHeadlineShown: false,
    },
  },
});
