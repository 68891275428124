import { createContext, useContext } from 'react';
import { Action } from './store/actions';
import { DataAccessState, initialState } from './store/reducer';
import { QueryClient } from 'react-query';
import { permissions } from '@axo/shared/data-access/types';

export function useHasRole(roleName: permissions.RoleName) {
  return (
    useContext(DataAccessContext).state.user.roles.find(
      (role) => role.Name === roleName
    ) !== undefined
  );
}

export function useHasAnyOfRoles(roleNames: permissions.RoleName[]) {
  const {
    state: {
      user: { roles },
    },
  } = useContext(DataAccessContext);
  const hasAnyOfRoles = roles.some((role) =>
    roleNames.find((name) => name === role.Name)
  );
  return hasAnyOfRoles;
}

export function useIsManager() {
  return useHasRole(permissions.RoleName.Manager);
}

export interface IDataAccessContext {
  queryClient: QueryClient | null;
  state: DataAccessState;
  dispatch: React.Dispatch<Action>;
  url: {
    api: string;
    ws?: string;
    integration?: string;
  };
}

export const DataAccessContext = createContext<IDataAccessContext>({
  queryClient: null,
  state: initialState,
  dispatch: () => undefined,
  url: { api: '', ws: '', integration: '' },
});

export function useAPI() {
  return getAPI(useContext(DataAccessContext));
}

export function getAPI(context: IDataAccessContext) {
  const {
    url: { api: service, integration, ws },
    state: {
      user: { token },
    },
  } = context;
  if (!service)
    throw new Error(
      'No service URL found! Configure service URL with <DataAccessContext.Provider>'
    );
  return { url: { service, integration, ws }, token };
}
