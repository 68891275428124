'use client';

import { useEffect, useState } from 'react';

export const useLoadScript = (scriptID: string, url: string) => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [isScriptError, setScriptError] = useState(false);

  useEffect(() => {
    // Attempt to get an existing script element
    let script = document.getElementById(scriptID) as HTMLScriptElement | null;
    if (script) {
      // If the script is already in the DOM and loaded
      if (script.getAttribute('data-loaded')) {
        setScriptLoaded(true);
        return;
      }
      // If the script is in the DOM but hasn't finished loading, attach the event listeners
      script.onload = () => setScriptLoaded(true);
      script.onerror = () => setScriptError(true);
      return;
    }

    // Create a new script element since one doesn't exist
    script = document.createElement('script');
    script.id = scriptID;
    script.src = url;
    script.async = true;
    script.onload = () => {
      script?.setAttribute('data-loaded', 'true');
      setScriptLoaded(true);
    };
    script.onerror = () => setScriptError(true);
    document.body.appendChild(script);

    // Cleanup function to remove event listeners
    return () => {
      script?.removeEventListener('load', () => setScriptLoaded(true));
      script?.removeEventListener('error', () => setScriptError(true));
    };
  }, [scriptID, url]);

  return { isScriptLoaded, isScriptError };
};
