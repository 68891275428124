'use client';

import { useCallback, useEffect, useState } from 'react';
import { CrossDomainAuthData } from './CrossDomainAuth.types';
import { useCrossDomainAuthRoute } from './useCrossDomainAuthRoute';
import { useCrossDomainAuthStorage } from './useCrossDomainAuthStorage';

type UseCrossDomainAuthReturn = {
  authData: Required<CrossDomainAuthData> | null;
  retrieve: () => Required<CrossDomainAuthData> | null;
};

/**
 * Retrieves the `id` (application) and `token` (jwt) from the url,
 * removes the token from the url, and stores both id and token in session storage.
 * Exposes the retrieved data as `authData` (either from url, or the stored values).
 *
 * @usage add on initial app load
 * @example
 * ```typescript
 * const { authData } = useCrossDomainAuth();
 * ```
 */
export const useCrossDomainAuth = (): UseCrossDomainAuthReturn => {
  const { retrieveAuthDataFromRoute } = useCrossDomainAuthRoute();
  const { retrieve } = useCrossDomainAuthStorage();

  const getAuthData = () => {
    if (typeof window === 'undefined') return null;

    const routeAuthData = retrieveAuthDataFromRoute({
      shouldClear: true,
      shouldStore: true,
    }) as Required<CrossDomainAuthData> | null;

    const storageAuthData = retrieve() as Required<CrossDomainAuthData> | null;

    return routeAuthData?.token
      ? routeAuthData
      : storageAuthData?.token
      ? storageAuthData
      : null;
  };

  const [authData, setAuthData] =
    useState<Required<CrossDomainAuthData> | null>(getAuthData);

  const retrieveAuthData = useCallback(() => {
    const data = getAuthData();
    setAuthData(data);
    return data;
  }, [retrieveAuthDataFromRoute, retrieve]);

  useEffect(() => {
    retrieveAuthData();
  }, [retrieveAuthData]);

  return {
    authData,
    retrieve: retrieveAuthData,
  };
};
