import { Hr } from '@axo/deprecated/util/ui-components';
import { IPersonData, StepContext } from '@axo/insurance/feature/providers';
import { useTranslation } from '@axo/shared/i18n';
import { TextInput } from '@axo/shared/ui/input';
import React, { ForwardedRef, useContext, useMemo } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import styles from './SelectCoverage.module.scss';

type IPersonDataFormProps = {
  formData: IPersonData;
  handleInputChange: (
    key: keyof IPersonData,
    value: Partial<IPersonData[typeof key]>
  ) => void;
  continueAttempts: number;
};

export const PersonDataForm = React.forwardRef(
  (
    {
      formData: { address, name },
      handleInputChange,
      continueAttempts,
    }: IPersonDataFormProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const {
      state: {
        steps: {
          selectCoverage: { isFormShown },
        },
      },
    } = useContext(StepContext);

    const AddressForm = useMemo(() => {
      return (
        <>
          <TextInput
            label={t('Address street')}
            onChange={(e) =>
              handleInputChange('address', { Street: e.target.value })
            }
            state={
              continueAttempts && address.Street.length === 0
                ? 'invalid'
                : 'undetermined'
            }
            value={address.Street}
          />
          <div className={styles.inlineInputContainer}>
            <div className={styles.inlineInputItem}>
              <TextInput
                label={t('Address city')}
                onChange={(e) =>
                  handleInputChange('address', { City: e.target.value })
                }
                state={
                  continueAttempts && address.City.length === 0
                    ? 'invalid'
                    : 'undetermined'
                }
                value={address.City}
              />
            </div>
            <div className={styles.inlineInputItem}>
              <TextInput
                label={t('Address zip')}
                onChange={(e) =>
                  handleInputChange('address', { ZipCode: e.target.value })
                }
                state={
                  continueAttempts && address.ZipCode.length === 0
                    ? 'invalid'
                    : 'undetermined'
                }
                value={address.ZipCode}
              />
            </div>
          </div>
        </>
      );
    }, [
      address.City,
      address.Street,
      address.ZipCode,
      continueAttempts,
      handleInputChange,
      t,
    ]);

    const NameForm = useMemo(() => {
      return (
        <div className={styles.inlineInputContainer}>
          <div className={styles.inlineInputItem}>
            <TextInput
              label={t('First name')}
              onChange={(e) =>
                handleInputChange('name', { FirstName: e.target.value })
              }
              state={
                continueAttempts > 0 && name.FirstName.length === 0
                  ? 'invalid'
                  : 'undetermined'
              }
              value={name.FirstName}
            />
          </div>
          <div className={styles.inlineInputItem}>
            <TextInput
              label={t('Last name')}
              onChange={(e) =>
                handleInputChange('name', { LastName: e.target.value })
              }
              state={
                continueAttempts > 0 && name.LastName.length === 0
                  ? 'invalid'
                  : 'undetermined'
              }
              value={name.LastName}
            />
          </div>
        </div>
      );
    }, [continueAttempts, handleInputChange, name.FirstName, name.LastName, t]);

    const isAnyFormShown = useMemo(() => {
      return Object.values(isFormShown).reduce(
        (previousValue, currentValue) => {
          return previousValue || currentValue;
        },
        false
      );
    }, [isFormShown]);

    return isAnyFormShown ? (
      <div ref={ref}>
        <Hr className={stepStyles.horizontalSeparator} />
        <p>{t('Address info')}</p>
        {isFormShown.name && NameForm}
        {isFormShown.address && AddressForm}
      </div>
    ) : null;
  }
);
