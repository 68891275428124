import {
  StepperAction,
  StepperReducer,
  StepperState,
} from '@axo/insurance/feature/providers';
import { useCreateInsurancePayment } from '@axo/shared/data-access/hooks';
import { insurance, insurance_payment } from '@axo/shared/data-access/types';
import { IStoredState, create, get, store } from './storedState';

function storeState<T extends Partial<IStoredState>>(
  states: T,
  stepperState: StepperState,
  reducer: StepperReducer,
  completeAction: StepperAction,
  paymentStateKey: string,
  completeStateKey: string
) {
  const payStep = reducer(stepperState, {
    type: 'Set step',
    scope: { parentType: 'step' },
    payload: 'verifyPaymentCard',
  });
  store<T & { stepContext: StepperState }>(paymentStateKey, {
    ...states,
    stepContext: payStep,
  });
  store<T & { stepContext: StepperState }>(completeStateKey, {
    ...states,
    stepContext: reducer(payStep, completeAction as StepperAction),
  });
}

function dataAction(
  payment: insurance_payment.Payment,
  paymentState: string,
  completeState: string
): StepperAction {
  return {
    type: 'Set step data',
    scope: { parentType: 'verifyPaymentCard' },
    payload: {
      payment,
      isCompleted: false,
      paymentStateKey: paymentState,
      completeStateKey: completeState,
    },
  };
}

export type StepperWithPaymentStepAction =
  | StepperAction
  | {
    type: 'Set step data';
    scope: { parentType: 'verifyPaymentCard' };
    payload: Partial<PaymentStep[keyof PaymentStep]>;
  };

export type PaymentStep = {
  verifyPaymentCard: {
    payment: insurance_payment.Payment | null;
    complete: boolean;
    paymentStateKey: string | null;
    completeStateKey: string | null;
  };
};

export function useCreateInsurancePaymentEpic<T extends object>({
  states,
  stepperState,
  stepperDispatch,
  reducer,
  completeAction,
}: {
  states: T;
  stepperState: StepperState;
  stepperDispatch: React.Dispatch<StepperAction>;
  reducer: StepperReducer;
  completeAction: StepperAction;
}) {
  const createInsurancePayment = useCreateInsurancePayment();

  function storePaymentState(
    payment: insurance_payment.Payment,
    paymentState: string,
    completeState: string
  ) {
    const stepDataAction = dataAction(payment, paymentState, completeState);
    stepperDispatch(stepDataAction as StepperAction);
    storeState<T>(
      states,
      reducer(stepperState, stepDataAction as StepperAction),
      reducer,
      completeAction,
      paymentState,
      completeState
    );
  }

  async function createPayment(
    insurance: insurance.InsurancePolicy,
    amount: number
  ) {
    const { CustomerID, ID: InsuranceID, MarketCountry } = insurance;
    const { url: paymentUrl, key: paymentState } = create();
    const { url: completeUrl, key: completeState } = create();
    const payment = await createInsurancePayment.mutateAsync({
      CustomerID,
      InsuranceID,
      MarketCountry,
      CompleteURL: completeUrl,
      PaymentURL: paymentUrl,
      UserAgent: window.navigator.userAgent,
      Amount: amount,
    });
    storePaymentState(payment, paymentState, completeState);
  }

  function usePayment(payment: insurance_payment.Payment) {
    const { key: paymentState } = get(new URL(payment.PaymentURL!));
    const { key: completeState } = get(new URL(payment.CompleteURL!));
    storePaymentState(payment, paymentState, completeState);
  }

  return {
    create: createPayment,
    use: usePayment,
    isLoading: createInsurancePayment.isLoading,
  };
}
