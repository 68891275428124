import { ContentBox } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import { ReactNode } from 'react';
import styles from './ContentBoxWrapper.module.scss';

type ContentBoxWrapperProps = {
  isEmbedded?: boolean;
  children: ReactNode;
  tagText?: string;
};

export function ContentBoxWrapper({
  isEmbedded = false,
  children,
  tagText,
}: ContentBoxWrapperProps) {
  return (
    <ContentBox
      tagText={tagText}
      classes={{
        root: classNames(styles.contentContainer),
        tag: styles.recommendedTag,
      }}
    >
      {children}
    </ContentBox>
  );
}
