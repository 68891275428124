import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import styles from './AccordionItem.module.scss';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { AccordionItemContent } from './AccordionItemContent';
import { AccordionItemHeader } from './AccordionItemHeader';

type IAccordionItemProps = {
  header?: ReactNode;
  children?: ReactNode;
  value: string;
  variant?: 'light' | 'dark';
  className?: string;
};

export const AccordionItem = ({
  header,
  children,
  value,
  variant = 'light',
  className,
}: IAccordionItemProps): ReactElement => {
  return (
    <RadixAccordion.Item
      className={clsx(styles.accordionItem, className)}
      value={value}
      data-variant={variant}
    >
      <AccordionItemHeader>{header}</AccordionItemHeader>
      <AccordionItemContent>{children}</AccordionItemContent>
    </RadixAccordion.Item>
  );
};
