'use client';

import { Label, Span } from '@axo/deprecated/util/ui-components';
import { getFormattedAmount } from '@axo/shared/util/formatters';
import clsx from 'clsx';
import RCSlider from 'rc-slider';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import styles from './Slider.module.scss';
// It is not possible to access the Slider components classes with css modules.

interface ILabel {
  value: string;
  label: string;
}

export type ISlider = {
  min: number;
  max: number;
  step?: number;
  label?: string;
  valueSuffix?: string;
  hideLegend?: boolean;
  rightLabel?: ILabel;
};

interface Props extends ISlider {
  value: number;
  onChange?: (value: number) => void;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
}

export const Slider: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    min,
    max,
    step,
    value,
    label,
    valueSuffix,
    onChange,
    onFocus,
    onBlur,
    hideLegend = false,
    rightLabel: secondValue,
  },
  ref
): JSX.Element => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={styles.container}>
      {label && (
        <div className={styles.headers}>
          <div className={styles.header}>
            <Label className={styles.bold}>{label ?? ''}</Label>
            <Span>{`${getFormattedAmount(value) ?? ''} ${
              valueSuffix ?? ''
            }`}</Span>
          </div>
          {secondValue && (
            <div className={clsx(styles.header, styles.right)}>
              <Label className={styles.bold}>{secondValue.label ?? ''}</Label>
              <Span>{secondValue.value ?? ''}</Span>
            </div>
          )}
        </div>
      )}

      <RCSlider
        ref={ref}
        min={min}
        max={max}
        step={step}
        value={localValue}
        ariaLabelForHandle={label}
        ariaValueTextFormatterForHandle={(v) => `${v} ${valueSuffix ?? ''}`}
        className={styles['rc-slider']}
        onChange={(v) => {
          onChange?.(v as number);
          setLocalValue(v as number);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {!hideLegend && (
        <div className={styles.sliderLegend}>
          <Span>{`${getFormattedAmount(min)} ${valueSuffix ?? ''}`}</Span>
          <Span>{`${getFormattedAmount(max)} ${valueSuffix ?? ''}`}</Span>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Slider);
