export const getFormattedAmount = (value: number | undefined): string => {
  if (!value) return '0';
  // stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

/* digit grouping : In typography, the thin space (&thinsp;) is recommended as a thousands separator because it provides just enough visual separation without disrupting the flow of the numbers. */
export const getFormattedAmountUI = (value: number | undefined): string => {
  if (!value) return '0';
  // stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u2009');
};

export const getRawAmount = (value: string | undefined): number => {
  if (!value) {
    return 0;
  }
  const rawValue = value.replace(/\s/g, '').replace(/[^0-9]/g, ''); // remove all characters
  return parseInt(rawValue, 10);
};
