export interface IInsurancePolicyKeyParams {
  insurancePolicyID: string;
}

export const insurancePolicyKeys = {
  root: (params: IInsurancePolicyKeyParams) =>
    ['insurance policy', params.insurancePolicyID] as const,
};

export function parseInsurancePolicyKey<
  T extends keyof typeof insurancePolicyKeys
>(
  queryKey: ReturnType<(typeof insurancePolicyKeys)[T]>
): IInsurancePolicyKeyParams {
  const [, insurancePolicyID] = queryKey;

  return { insurancePolicyID };
}
