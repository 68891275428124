import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import styles from './AccordionItem.module.scss';

type IAccordionItemContentProps = {
  children: ReactNode;
  className?: string;
};

export const AccordionItemContent = forwardRef(
  (
    { children, className, ...props }: IAccordionItemContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ): ReactElement => (
    <RadixAccordion.Content
      className={clsx(styles.accordionItemContent, className)}
      {...props}
      ref={forwardedRef}
    >
      <div className={styles.content}>{children}</div>
    </RadixAccordion.Content>
  )
);
