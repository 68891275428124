import { HTMLAttributes, LiHTMLAttributes, ReactNode } from 'react';
import { classNames } from '@axo/shared/util/dom';
import { Check, ICheck } from '@axo/shared/ui/atoms';
import styles from './CheckList.module.scss';

export interface CheckListItemProps
  extends ICheck,
    Omit<LiHTMLAttributes<HTMLLIElement>, 'children' | 'role'> {
  label: ReactNode;
}

const Item = ({
  label,
  className,
  state,
  iconType,
  ...props
}: CheckListItemProps) => (
  <li {...props} className={classNames(styles.CheckListItem, className)}>
    <span className={styles.icon}>
      <Check state={state} iconType={iconType} />
    </span>
    <span>{label}</span>
  </li>
);

export type CheckListProps = Omit<HTMLAttributes<HTMLUListElement>, 'role'>;

export const CheckList = ({
  className,
  children,
  ...props
}: CheckListProps) => (
  <ul {...props} className={classNames(styles.CheckList, className)}>
    {children}
  </ul>
);

CheckList.Item = Item;

export default CheckList;
