export enum EventCode {
  ViewStep = 'view-step',
  SelectCoverage = 'select-coverage',
  OpenProductFactSheet = 'open-product-fact-sheet',
  OpenPrePurchaseInformation = 'open-pre-purchase-information',
  UnhandledError = 'unhandled-error',
  LoanQuotePresentationLoaded = 'loan-quote-presentation-loaded',
  GoToBank = 'go-to-bank',
  ToggleDetails = 'toggle-details',
  OpenChangeOfferModal = 'open-change-offer-modal',
  CloseChangeOfferModal = 'close-change-offer-modal',
  ChangeOffer = 'change-offer',
  OpenAcceptOfferModal = 'open-accept-offer-modal',
  CloseAcceptOfferModal = 'close-accept-offer-modal',
  AcceptOffer = 'accept-offer',
  PayExEvent = 'payex-event',
  InsuranceDeclined = 'insurance-declined',
  InsuranceDeclinedDuringPayment = 'insurance-declined-during-payment',
  PaymentFailed = 'payment-failed',
  InsuranceNext = 'insurance-next',
  InsuranceBack = 'insurance-back',
}
