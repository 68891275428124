import { createContext } from 'react';
import { LoanApplicationAction } from './actions';
import { loanApplicationInitialState, LoanApplicationState } from './reducer';

export const LoanApplicationContext = createContext<{
  state: LoanApplicationState;
  dispatch: React.Dispatch<LoanApplicationAction>;
}>({
  state: loanApplicationInitialState,
  dispatch: () => undefined,
});
