const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const waitUntil = async (
  conditionFunc: () => boolean,
  intervalTime = 250,
  timeout = 5000,
  error = 'Operation timed out'
) => {
  const startTime = Date.now();
  while (Date.now() - startTime < timeout) {
    if (conditionFunc()) return;
    await delay(intervalTime);
  }
  throw new Error(error);
};
