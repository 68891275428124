import { H4 } from '@axo/deprecated/util/ui-components';
import { HTMLAttributes } from 'react';
import styles from './InsuranceHeading.module.css';
import { classNames } from '@axo/shared/util/dom';

export function InsuranceSubHeading({
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) {
  return <H4 className={classNames(className, styles.subHeading)} {...props} />;
}
