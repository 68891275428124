import { ContentBox, IContentBox } from '@axo/deprecated/util/ui-components';
import styles from './ValidationContentBox.module.scss';
import { classNames } from '@axo/shared/util/dom';

export interface ValidationContentBoxProps extends IContentBox {
  invalid?: boolean;
}

export function ValidationContentBox({
  invalid,
  classes,
  ...props
}: ValidationContentBoxProps) {
  return (
    <ContentBox
      classes={{
        root: classNames(classes?.root, invalid && styles.invalid),
      }}
      {...props}
    />
  );
}
