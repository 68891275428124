import { QueryFunctionContext, QueryKey, QueryFunction } from 'react-query';

export type SendFunction<TParams, TResult> = (
  params: TParams
) => Promise<TResult>;

export function queryFunction<TQueryKey extends QueryKey, TParams, TResult>(
  send: SendFunction<TParams, TResult>,
  params: (queryFunctionContext: QueryFunctionContext<TQueryKey>) => TParams
): QueryFunction<TResult, TQueryKey> {
  return (context) => send(params(context));
}
