import { StatusGroup } from '@axo/shared/data-access/types';

export interface IPersonKeyParams {
  personID?: string | null;
  offset?: number;
  limit?: number;
  groups?: StatusGroup[];
}

export const personKeys = {
  root: (params: IPersonKeyParams) => ['person', params.personID] as const,
  insurance: (params: IPersonKeyParams) =>
    [...personKeys.root(params), 'insurance'] as const,
  loanApplications: (params: IPersonKeyParams) =>
    [
      ...personKeys.root(params),
      'loan applications',
      params.offset,
      params.limit,
      ...(params.groups || []),
    ] as const,
};

export function parsePersonKey<T extends keyof typeof personKeys>(
  queryKey: ReturnType<(typeof personKeys)[T]>
) {
  const [, personID, , offset, limit, ...groups] = queryKey;

  return {
    personID,
    offset,
    limit,
    groups,
  };
}
