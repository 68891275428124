import { FC, ReactNode, useEffect, useState } from 'react';
import styles from './Checkbox.module.scss';
import useGeneratedId from '../../../hooks/useGeneratedId';
import { Check } from '@axo/shared/ui/atoms';
import { Span } from '../StyledElements';
import { ErrorModel, IInputProps } from '../../../models';
import ErrorText from '../ErrorText';
import classNames from '../../../utils/class-names';
import sanitizeHtml from 'sanitize-html';

enum ClassNames {
  Root = 'root',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface ICheckbox
  extends IInputProps<HTMLInputElement, boolean | undefined> {
  errors?: ErrorModel[];
  children?: ReactNode;
  label?: string;
  classes?: ClassNamesProp;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Checkbox: FC<ICheckbox> = ({
  children = '',
  value = false,
  errors = [],
  name,
  label,
  onChange,
  onFocus,
  onBlur,
  onClick,
  classes,
}: ICheckbox) => {
  const inputId = useGeneratedId();
  const [isChecked, setIsChecked] = useState<boolean>(value);
  useEffect(() => setIsChecked(value), [value]);
  const [hover, setHover] = useState<boolean>(false);

  return (
    <label
      className={classNames(
        'Checkbox',
        styles.root,
        isChecked ? styles.checked : styles.unchecked,
        errors.length > 0 && styles.invalid,
        hover && styles.hover,
        classes?.root
      )}
      htmlFor={inputId}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.row}>
        <div className={styles.checkmark}>
          <Check state={errors.length === 0 ? 'valid' : 'invalid'} />
        </div>
        <input
          id={inputId}
          name={name}
          checked={isChecked}
          type="checkbox"
          className={styles.checkbox}
          onChange={(e) => {
            setIsChecked(value);
            onChange?.(e);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        />
        <div className={styles.column}>
          {children && <Span>{children}</Span>}
          {label && (
            <Span dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }} />
          )}

          {errors.length > 0 && <ErrorText errors={errors} />}
        </div>
      </div>
    </label>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Checkbox;
