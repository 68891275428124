import { REDIRECT_URL } from '@axo/insurance/const/fi';

type RedirectArgs = {
  uid?: string;
  token?: string;
};

export const redirect = (args?: RedirectArgs) => {
  let redirectUrl = new URL(REDIRECT_URL);

  if (!args) {
    // Redirect user
    window.location.href = redirectUrl.href;
    return;
  }

  const { token, uid } = args;

  const params = new URLSearchParams(window.location.search);

  if (params.has('redirectUrl')) {
    redirectUrl = new URL(params.get('redirectUrl') ?? REDIRECT_URL);
    if (uid) redirectUrl.searchParams.set('id', uid);
    if (token) redirectUrl.hash = `token=${token}`;
  }

  // Redirect user
  window.location.href = redirectUrl.href;
};
