export interface IFormatterOptions {
  remove?: RegExp | string; // Removes any matches of the regex/string
  placeholder?: string;
  allowAppendingAtEndOfPattern?: boolean;
}

export abstract class AbstractFormatter {
  abstract format(input: string): string;
  abstract unformat(input: string): string;
  /**
   * Assumes a cursor position in a string (e.g. an input field with a value and cursor position).
   * Returns how much the cursor's position would have been moved by applying
   * the formatter to the string.
   */
  abstract getOffsetAtCursorPosition(position: number): number;
  abstract fitsInFormat(value: string): boolean;
  abstract hasNoPlaceholderAt(index: number): boolean;
}

/**
 * Formatter that does nothing. Used when you want no formatting.
 */
export class IdentityFormatter extends AbstractFormatter {
  format(input: string): string {
    return input;
  }
  unformat(input: string): string {
    return input;
  }
  getOffsetAtCursorPosition(): number {
    return 0;
  }
  fitsInFormat(): boolean {
    return true;
  }
  hasNoPlaceholderAt(): boolean {
    return false;
  }
}

/**
 * The Formatter can be used to add characters in a string, force the max length of a string
 * and has some methods that are helpful for working with text cursors in a text field that
 * is using the Formatter. It also contains some helpful methods for creating new formats based
 * on a preexisting format, which can be used to simplify the creation of new formats.
 *
 * A format string consists of two categories of substrings: the placeholder and any other characters.
 * By default the placeholder is set to "*", meaning that a format for turning the string "123456" to
 * "123-456" would look like "***-***".
 */
export class Formatter extends AbstractFormatter {
  protected _pattern: string;
  protected _placeholder: string;
  protected removeMatcher: RegExp;
  protected _allowOverflowAfterPattern: boolean;

  constructor(
    pattern: string,
    {
      placeholder = '*',
      remove = '',
      allowAppendingAtEndOfPattern = false,
    }: IFormatterOptions = {}
  ) {
    super();
    this._pattern = pattern;
    this._placeholder = placeholder;
    this.removeMatcher = toGlobalRegex(remove);
    this._allowOverflowAfterPattern = allowAppendingAtEndOfPattern;
  }

  /**
   * A format segment is a string with zero or more format characters and ending in one spot character.
   * For the format "*-**...*" the segments would be "*", "-*", "*" and "...*".
   */
  protected getFormatSegments() {
    return (
      this._pattern.match(new RegExp(`(.*?\\${this._placeholder})`, 'g')) || []
    );
  }

  format(input: string): string {
    if (this.removeMatcher)
      input = input.replace(new RegExp(this.removeMatcher, 'g'), '');
    let output = '';
    const segments = this.getFormatSegments();
    const endLoop = Math.min(input.length, segments.length);

    for (let i = 0; i < endLoop; i++) {
      output += segments[i].replace(this._placeholder, input[i]);
    }

    if (this._allowOverflowAfterPattern) {
      output += input.substring(segments.length);
    }

    return output;
  }

  unformat(input: string): string {
    input = input.replaceAll(
      new RegExp(`[${this._pattern.replaceAll(this._placeholder, '')}]`, 'g'),
      ''
    );
    if (this.removeMatcher) {
      input = input.replaceAll(this.removeMatcher, '');
    }
    if (!this._allowOverflowAfterPattern) {
      input = input.substring(0, this.getFormatSegments().length);
    }

    return input;
  }

  /**
   * Assumes a cursor position in a string (e.g. an input field with a value and cursor position).
   * Returns how much the cursor's position would have been moved by applying
   * the formatter to the string.
   */
  getOffsetAtCursorPosition(position: number): number {
    const segments = this.getFormatSegments();

    let offset = 0;
    let i = 0;
    for (const segment of segments) {
      if (i >= position - 1) break;
      offset += segment.length - 1;
      i += segment.length;
    }
    return offset;
  }

  fitsInFormat(value: string): boolean {
    if (this._allowOverflowAfterPattern) return true;
    return this.getFormatSegments().length >= value.length;
  }

  hasNoPlaceholderAt(index: number) {
    if (index < 0) return false;
    const character = this._pattern[index];
    return !character || character !== this._placeholder;
  }

  static builder(): FormatterBuilder {
    return new FormatterBuilder('');
  }
}

class FormatterBuilder extends Formatter {
  withPlaceholder(placeholder: string) {
    this._placeholder = placeholder;
    return this;
  }

  withPattern(pattern: string) {
    this._pattern = pattern;
    return this;
  }

  reversePattern() {
    this._pattern = this._pattern.split('').reverse().join('');
    return this;
  }

  repeatPatternToLength(length: number) {
    const repeatedFormat = [];
    const segments = this.getFormatSegments();
    const tail = this.getTrailingCharactersOfFormat();
    for (let i = 0; i < length; i++) {
      repeatedFormat.push(segments[i % segments.length]);
      if (i % segments.length === segments.length - 1 && i < length - 1) {
        repeatedFormat.push(tail);
      }
    }
    this._pattern = repeatedFormat.join('') || '';
    return this;
  }

  private getTrailingCharactersOfFormat() {
    return (
      this._pattern.match(new RegExp(`([^${this._placeholder}]$)`))?.[0] || ''
    );
  }

  shouldAllowOverflow(allow: boolean) {
    this._allowOverflowAfterPattern = allow;
    return this;
  }

  removeCharactersMatching(matcher: string | RegExp) {
    this.removeMatcher = toGlobalRegex(matcher);
    return this;
  }

  build() {
    return new Formatter(this._pattern, {
      placeholder: this._placeholder,
      remove: this.removeMatcher,
      allowAppendingAtEndOfPattern: this._allowOverflowAfterPattern,
    });
  }
}

function toGlobalRegex(input: string | RegExp) {
  return new RegExp(typeof input === 'string' ? input : input.source, 'g');
}
