import { useContext } from 'react';
import { useMutation } from 'react-query';
import { customer } from '@axo/shared/data-access/types';
import { customer_api } from '@axo/shared/data-access/api';
import { DataAccessContext } from '@axo/shared/data-access/provider';

export function useCreateCustomer() {
  const {
    url: { api },
  } = useContext(DataAccessContext);

  return useMutation((customer: customer.CreateCustomer) =>
    customer_api.postCustomer(api, customer)
  );
}
