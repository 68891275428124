import { useCallback, useContext } from 'react';
import { InsuranceContext } from './store/context';
import { insurance_meta_hooks } from '@axo/shared/data-access/hooks';

export function useCreateInsuranceEvent() {
  const { state } = useContext(InsuranceContext);
  const createEvent = insurance_meta_hooks.useCreateEvent();

  return useCallback(
    (eventName: string, eventValue: string) => {
      const policyID = state.insurancePolicyReference?.PolicyID;

      if (!policyID) {
        return;
      }

      createEvent.mutate({
        PolicyID: policyID,
        Name: eventName,
        Value: eventValue,
      });
    },
    [createEvent, state.insurancePolicyReference?.PolicyID]
  );
}
