import { createElement, FC } from 'react';
import { capitalizeFirstLetter } from '../../../utils/string-utils';
import styles from './StyledElements.module.scss';

/**
 * This is a factory function that returns a small React component that wraps
 * around an HTML element. This component will take the same props as the
 * element, but it will also add an extra css class that allows us to add module
 * scoped styling to the element. It will pass along any props and appends the
 * additional class to the end of the pre-existing className (if it is set).
 * @param elementName
 * @returns
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const createStyledElement = <T extends keyof JSX.IntrinsicElements>(
  elementName: T
): FC<JSX.IntrinsicElements[T]> => {
  const component: FC<JSX.IntrinsicElements[T]> = ({
    children,
    className,
    ...props
  }) => {
    return createElement(
      elementName,
      {
        className: (className || '') + ` ${styles[elementName]}`,
        ...props,
      },
      children
    );
  };
  component.displayName = capitalizeFirstLetter(elementName);
  return component;
};

/**
 * These are the exported element wrappers that can be used in place of normal
 * HTML elements in other components.
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const H1 = createStyledElement('h1');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const H2 = createStyledElement('h2');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const H3 = createStyledElement('h3');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const H4 = createStyledElement('h4');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const H5 = createStyledElement('h5');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const H6 = createStyledElement('h6');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Span = createStyledElement('span');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const P = createStyledElement('p');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const A = createStyledElement('a');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Ul = createStyledElement('ul');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Hr = createStyledElement('hr');
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Img = createStyledElement('img');
