/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const getFormattedAmount = (value: number): string =>
  // stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const getRawAmount = (value: string): number => {
  const rawValue = value.replace(/\s/g, '').replace(/[^0-9]/g, ''); // remove all characters
  return parseInt(rawValue, 10);
};
