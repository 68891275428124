import { insurance_request } from '@axo/shared/data-access/types';
import { LoadedLoanApplication } from '@axo/shared/feature/providers';

type CreateInsuranceRequestDataArgs = {
  application: LoadedLoanApplication;
  source: string;
};

export const createInsuranceRequestData = ({
  application,
  source,
}: CreateInsuranceRequestDataArgs) => {
  const result = {
    CustomerID: application.CustomerID || '',
    PersonID: application.PersonID || '',
    MarketCountry: application.MarketCountry,
    LoanApplicationID: application.ID,
    Source: source as string,
  } satisfies insurance_request.Create;

  const cid = new URLSearchParams(window.location.search).get('cid');
  if (cid && cid !== 'null') {
    Object.assign(result, { ClientID: cid });
  }

  return result;
};
