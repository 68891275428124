import { FC } from 'react';
import { classNames } from '@axo/shared/util/dom';
import styles from './Check.module.scss';

export interface ICheck {
  state:
    | 'unfocused'
    | 'focused'
    | 'valid'
    | 'invalid'
    | 'disabled'
    | 'inactive'
    | 'info';
  iconType?: IconType;
}

export enum IconType {
  Default = 'default',
  Circle = 'circle',
  Badge = 'badge',
}

export const Check: FC<ICheck> = ({
  state,
  iconType = IconType.Default,
}: ICheck) => {
  if (iconType === IconType.Default || iconType === IconType.Circle) {
    return (
      <svg
        role="img"
        className={classNames(
          styles.check,
          styles[state],
          iconType === IconType.Circle && styles.circle
        )}
        fill="none"
        viewBox="0 -1 14 14"
      >
        <title>{`${state} checkmark icon`}</title>
        <path
          d="m12.552 1.833-7.037 8.333-4.075-3.41"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (iconType === IconType.Badge) {
    return (
      <svg
        role="img"
        viewBox="0 -1 24 25"
        fill="none"
        className={classNames(styles.check, styles[state], styles.badge)}
      >
        <title>{`${state} checkmark badge icon`}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.52032 4.64597C8.292 4.58439 9.02459 4.28094 9.61379 3.77883C10.9888 2.60709 13.011 2.60709 14.386 3.77883C14.9752 4.28094 15.7078 4.58439 16.4795 4.64597C18.2803 4.78968 19.7103 6.21965 19.854 8.02044C19.9155 8.79212 20.219 9.52471 20.7211 10.1139C21.8928 11.4889 21.8928 13.5112 20.7211 14.8861C20.219 15.4753 19.9155 16.2079 19.854 16.9796C19.7103 18.7804 18.2803 20.2104 16.4795 20.3541C15.7078 20.4157 14.9752 20.7191 14.386 21.2212C13.011 22.393 10.9888 22.393 9.61379 21.2212C9.02459 20.7191 8.292 20.4157 7.52032 20.3541C5.71953 20.2104 4.28955 18.7804 4.14585 16.9796C4.08427 16.2079 3.78082 15.4753 3.2787 14.8861C2.10697 13.5112 2.10697 11.4889 3.2787 10.1139C3.78082 9.52471 4.08427 8.79212 4.14585 8.02044C4.28955 6.21965 5.71953 4.78968 7.52032 4.64597ZM16.4484 10.9486C16.9171 10.4799 16.9171 9.72013 16.4484 9.2515C15.9798 8.78287 15.22 8.78287 14.7514 9.2515L10.7999 13.203L9.24843 11.6515C8.7798 11.1829 8.02 11.1829 7.55137 11.6515C7.08275 12.1201 7.08275 12.8799 7.55137 13.3486L9.95137 15.7486C10.42 16.2172 11.1798 16.2172 11.6484 15.7486L16.4484 10.9486Z"
        />
      </svg>
    );
  }
};

export default Check;
