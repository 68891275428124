import { classNames } from '@axo/shared/util/dom';
import {
  AbstractFormatter,
  Formatter,
  Formatters,
} from '@axo/shared/util/string';
import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './FormattedInput.module.scss';
import { FormattedInputCursorController } from './FormattedInputCursorController';
import withFrozenTextCursor from './withFrozenTextCursor';

// subset of all the possibilities for autoComplete. For complete list: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
type AutoCompleteValues = 'on' | 'off' | 'tel-national';

export type FormatterFactory = (value: string) => AbstractFormatter;

enum ClassNames {
  Root = 'root',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IFormattedInput
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  format?: string | FormatterFactory;
  prefix?: string;
  suffix?: string;
  autoComplete?: AutoCompleteValues;
  classes?: ClassNamesProp;
  size?: 'large';
}

// Todo: refactor this component if possible.
const FormattedInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IFormattedInput
> = (
  {
    format,
    prefix = '',
    suffix = '',
    autoComplete = 'off',
    classes,
    size,
    ...htmlProps
  },
  forwardRef
) => {
  const getFormatter =
    typeof format === 'function'
      ? format
      : format
      ? () => Formatter.builder().withPattern(format).build()
      : () => Formatters.unformatted();
  const paddedSuffix = suffix ? ' ' + suffix : '';
  const paddedPrefix = prefix ? prefix + ' ' : '';

  const [formattedValue, setFormattedValue] = useState<string>(
    htmlProps.value?.toString() ?? ''
  );
  const [previousFormatter, setPreviousFormatter] =
    useState<AbstractFormatter | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const updateRef = () => {
      if (!forwardRef) return;
      if (typeof forwardRef === 'function') {
        forwardRef(ref.current ?? null);
      } else {
        forwardRef.current = ref.current ?? null;
      }
    };

    updateRef();
    return updateRef;
  }, []);

  const [cursorHandler] = useState<FormattedInputCursorController>(
    new FormattedInputCursorController(ref)
  );

  useEffect(() => {
    cursorHandler?.setSuffixLength(paddedSuffix.length);
  }, [cursorHandler, paddedSuffix]);

  const unformat = (value: string) => {
    if (typeof value !== 'string') return '';
    value = removeSuffix(value, paddedSuffix);
    if (previousFormatter) {
      value = previousFormatter.unformat(value);
    }
    return value;
  };

  const reformat = (value: string) => {
    if (typeof value !== 'string') return { unformatted: '', formatted: '' };
    value = unformat(value);
    const unformatted = value;

    const formatter = getFormatter(value);

    if (!formatter.fitsInFormat(value)) {
      value = formattedValue;
    } else {
      value = formatter.format(value) + paddedSuffix;
    }

    cursorHandler.setFormatter(formatter);
    setFormattedValue(value);
    setPreviousFormatter(formatter);

    return { unformatted, formatted: value };
  };

  // Apply formatting when the value is changed from a parent component.
  useEffect(() => {
    const { formatted } = reformat(htmlProps.value?.toString() ?? '');
    setFormattedValue(formatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlProps.value, suffix]);

  const placeholderCheck = formattedValue || !htmlProps.placeholder;

  // code related to bug ONE-8289
  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    if (typeof navigator === 'undefined') return;
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);
  // end of code related to bug ONE-8289

  return (
    <>
      {paddedPrefix && placeholderCheck && (
        <span className={styles.prefix}>{paddedPrefix}</span>
      )}

      {/* To prevent a bug where autofill in safari would interfere with the inputs.
        Suggestion was to add two fake fields under <form>, but in our solution that did not work.
        It has to be added two fake fields for every field for this to work in our setup ONE-8289 */}

      {isSafari && (
        <div style={{ height: 0, overflow: 'hidden', position: 'absolute' }}>
          <input
            type="text"
            id="prevent_autofill"
            name="prevent_autofill"
            autoComplete="off"
            tabIndex={-1}
          />
          <input
            type="password"
            id="password_fake"
            name="password_fake"
            autoComplete="off"
            tabIndex={-1}
          />
        </div>
      )}

      <input
        {...htmlProps}
        value={formattedValue}
        autoComplete={autoComplete}
        className={classNames(
          styles.input,
          classes?.root,
          htmlProps.className,
          size === 'large' && styles.large
        )}
        onChange={(e) => {
          const { unformatted, formatted } = reformat(e.target.value);
          if (!ref.current) return;
          withFrozenTextCursor(ref.current, () => {
            e.target.value = unformatted;
            htmlProps.onChange?.(e);
            /* Changing the input's value in code will reset the text cursor
                        position to the end of the input. However, by using the
                        withFrozenTextCursor method, we can make sure the cursor position
                        does not change. Since updating the value passed as a prop to the
                        <input> cannot be wrapped in a method, we change the value manually
                        here. Then when the component re-renders, the formattedValue state
                        variable will be the same as the <input>'s value, so it won't be
                        replaced. */
            e.target.value = formatted;
          });

          cursorHandler?.afterInputValueChange();

          // htmlProps.onChange?.(replaceEventValue(e, unformatted));
        }}
        onKeyDown={(e) => {
          // Run afterCursorMove after the input has updated
          setTimeout(() => {
            cursorHandler?.afterCursorMove();
          }, 0);
          htmlProps.onKeyDown?.(e);
        }}
        onClick={(e) => {
          cursorHandler?.afterCursorMove();
          htmlProps.onClick?.(e);
        }}
        onBlur={(e) => {
          e.target.value = unformat(e.target.value);
          htmlProps.onBlur?.(replaceEventValue(e, unformat(e.target.value)));
        }}
        onFocus={(e) =>
          htmlProps.onFocus?.(replaceEventValue(e, unformat(e.target.value)))
        }
        ref={ref}
      />
    </>
  );
};

const removeSuffix = (value: string, suffix: string) =>
  value.replace(new RegExp(`${suffix}$`), '');

/**
 * Returns a new event object with a cloned target and the event.target.value replaced.
 * Note that this detaches the target from the DOM, so updates in the object
 * will not affect the DOM and vice versa.
 */
const replaceEventValue = <T extends SyntheticEvent<HTMLInputElement>>(
  e: T,
  newValue: string
): T => {
  if (e.target instanceof Element) {
    const n = e.target.cloneNode() as HTMLInputElement;
    n.value = newValue;

    return {
      ...e,
      target: n,
    };
  } else {
    return e;
  }
};

export default forwardRef(FormattedInput);
