import { useTranslation } from '@axo/shared/i18n';
import { ReactNode } from 'react';
import styles from './ErrorView.module.scss';

interface IError {
  title: ReactNode;
  description: ReactNode;
}

export function ErrorView({ title, description }: IError) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h1>{title}</h1>
      <p>{description}</p>
      <button onClick={() => window.location.reload()}>{t('Reload')}</button>
    </div>
  );
}
