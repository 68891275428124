import clsx from 'clsx';
import { FC } from 'react';
import { ICSSColorVariable } from '../../../models/color.model';
import CircularProgress, {
  ICircularProgress,
} from '../../atoms/CircularProgress';
import { IStepper, IStepperStep, IStepperStepState } from '../Stepper';

import styles from './StepperMobile.module.scss';

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const StepperMobile: FC<IStepper> = ({
  steps = [],
  locale,
  completeApplicationLabel,
}) => {
  const activeIndex = steps.reduce(
    (progress, step, i) => (step.state === 'active' ? i : progress),
    0
  );
  const totalSteps = steps.length;
  const activeStep = steps[activeIndex];
  const title = activeStep.title ?? activeStep.name;
  const locText = getLocalizedText(locale);

  const nextStep =
    activeIndex < steps.length - 1
      ? steps[activeIndex + 1].title ?? steps[activeIndex + 1].name
      : completeApplicationLabel ?? locText.completeApplication;

  const progressSegments = stepsToCircularProgressSegments(steps);

  return (
    <div className={clsx('StepperMobile', styles.root)}>
      <div className={styles.progressWrapper}>
        {progressSegments.map((segment) => (
          <CircularProgress
            key={`${segment.from}-${segment.to}`}
            {...segment}
          />
        ))}
        <div className={styles.progressText}>
          {activeIndex + 1} / {totalSteps}
        </div>
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.nextStepTitle}>
          {locText.nextStep}: {nextStep}
        </div>
      </div>
    </div>
  );
};

const stepsToCircularProgressSegments = (
  steps: IStepperStep[]
): ICircularProgress[] => {
  return steps.reduce((segments: ICircularProgress[], step, i) => {
    const segment = segments[segments.length - 1];

    const color = getStepStateColor(step.state);

    if (!segment || segment.color !== color) {
      segments.push({
        from: i,
        to: i + 1,
        color: color,
        steps: steps.length,
        startCapColor: '--white-100',
        endCapColor: '--white-100',
      });
    } else {
      segment.to++;
    }
    return segments;
  }, []);
};

const getStepStateColor = (state: IStepperStepState): ICSSColorVariable => {
  switch (state) {
    case 'active':
      return '--dark-200';
    case 'completed':
    case 'touched':
      return '--success-300';
    case 'untouched':
      return '--light-400';
  }
};

interface LocalizedText {
  nextStep: string;
  completeApplication: string;
}

const getLocalizedText = (locale: string): LocalizedText => {
  switch (locale?.toLowerCase()) {
    case 'fi':
      return {
        nextStep: 'Seuraavana',
        completeApplication: 'Lähetä lainahakemus',
      };
    case 'no':
      return {
        nextStep: 'Neste steg',
        completeApplication: 'Send inn søknaden',
      };
    case 'se':
      return {
        nextStep: 'Nästa steg',
        completeApplication: 'Skicka in ansökan',
      };
    case 'dk':
      return {
        nextStep: 'Næste',
        completeApplication: 'Submit',
      };
    case 'en':
    default:
      return {
        nextStep: 'Next step',
        completeApplication: 'Send loan application',
      };
  }
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default StepperMobile;
