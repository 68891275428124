import { clearCrossDomainAuth } from './store/clear-cross-domain-auth';
import { retrieveCrossDomainAuth } from './store/retrieve-cross-domain-auth';
import { storeCrossDomainAuth } from './store/store-cross-domain-auth';

export const useCrossDomainAuthStorage = () => {
  return {
    store: storeCrossDomainAuth,
    retrieve: retrieveCrossDomainAuth,
    clear: clearCrossDomainAuth,
  };
};
