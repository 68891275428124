import { getExpiryFromJwt } from './get-expiry-from-jwt';

export const isJwtExpired = (jwt: string): boolean => {
  if (!jwt) return true;

  try {
    const expiry = getExpiryFromJwt(jwt);
    const currentTime = Math.floor(Date.now() / 1000);

    return !expiry || expiry < currentTime;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return true;
  }
};
