/* eslint-disable axo-frontend/directory-structure */
import {
  InsuranceContext,
  InsuranceState,
  StepContext,
  stepperReducer,
  useCreateInsuranceEvent,
} from '@axo/insurance/feature/providers';
import { Spinner } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import {
  useInsurancePayments,
  useInsurancePolicy,
  usePerson,
} from '@axo/shared/data-access/hooks';
import {
  DataAccessContext,
  DataAccessState,
} from '@axo/shared/data-access/provider';
import {
  LoanApplicationContext,
  LoanApplicationState,
} from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext } from 'react';
import {
  PowerOfAttorney as SharedPowerOfAttorney,
  useCreateInsurancePaymentEpic,
  useExistingPayment,
} from '../../../shared';

export const PowerOfAttorney = () => {
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { state: insuranceContext } = useContext(InsuranceContext);
  const { dispatch, state, isEmbedded, onEnd } = useContext(StepContext);
  const insurancePolicy = useInsurancePolicy(
    insuranceContext.insurancePolicyReference?.PolicyID ?? undefined
  ).data;
  const person = usePerson(insurancePolicy?.PersonID);
  const log = useEventLogger();
  const addEvent = useCreateInsuranceEvent();
  const { track } = useAnalytics();
  useEventField('current-step', 'power-of-attorney');

  const {
    steps: {
      powerOfAttorney: stepData,
      selectCoverage: { personData, insurance },
    },
  } = state;

  type States = {
    dataAccessContext: DataAccessState;
    applicationContext: LoanApplicationState;
    insuranceContext: InsuranceState;
  };

  const insurancePayment = useCreateInsurancePaymentEpic<States>({
    states: { dataAccessContext, applicationContext, insuranceContext },
    stepperState: state,
    stepperDispatch: dispatch,
    reducer: stepperReducer,
    completeAction: {
      type: 'Set step data',
      scope: { parentType: 'verifyPaymentCard' },
      payload: {
        isCompleted: true,
      },
    },
  });

  const paymentsForPolicy = useInsurancePayments(
    insuranceContext.insurancePolicyReference?.PolicyID ?? '',
    applicationContext.application?.MarketCountry ?? ''
  );

  const existingPayment = useExistingPayment(
    state.steps.verifyPaymentCard.payment,
    paymentsForPolicy.data?.Items
  );

  if (insuranceContext.insurancePolicyReference === null) {
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  if (insurancePolicy === undefined) return <Spinner />;

  async function handleNext() {
    if (insurancePolicy === undefined) return;
    const prev = existingPayment;

    if (prev) {
      insurancePayment.use(prev);
    } else {
      await insurancePayment.create(
        insurancePolicy,
        insurance.payout ?? insurancePolicy?.Amount
      );
    }

    log(EventCode.InsuranceNext);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'completed' },
    });
    track({
      event: `Insurance Step ${stepData.name} Completed`,
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'verifyPaymentCard',
    });
  }

  function handleBack() {
    log(EventCode.InsuranceBack);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'terms',
    });
  }

  async function continueWithoutInsurance() {
    addEvent('fi/form/insurance-declined', 'payment');
    await track({
      event: 'Insurance Declined',
      options: { send_immediately: true },
      params: { step: 'powerOfAttorney' },
    });
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  const firstName =
    insuranceContext.insurancePolicyReference?.FirstName ??
    personData.name.FirstName;
  const lastName =
    insuranceContext.insurancePolicyReference?.LastName ??
    personData.name.LastName;

  return (
    <SharedPowerOfAttorney
      hasConfirmedPowerOfAttorney={stepData.hasConfirmedPowerOfAttorney}
      data={{
        firstName: firstName,
        lastName: lastName,
        socialSecurityNumber: person.data?.SocialSecurityNumber,
      }}
      title={stepData.title}
      isEmbedded={isEmbedded}
      onCancel={continueWithoutInsurance}
      onNext={handleNext}
      onBack={handleBack}
      onChange={(value) => {
        dispatch({
          type: 'Set step data',
          scope: { parentType: 'powerOfAttorney' },
          payload: {
            hasConfirmedPowerOfAttorney: value,
          },
        });
      }}
    />
  );
};
