export interface IInsuranceRequestKeyParams {
  insuranceRequestID: string;
}

export const insuranceRequestKeys = {
  root: (params: IInsuranceRequestKeyParams) =>
    ['insurance request', params.insuranceRequestID] as const,
};

export function parseInsuranceRequestKey<
  T extends keyof typeof insuranceRequestKeys
>(
  queryKey: ReturnType<(typeof insuranceRequestKeys)[T]>
): IInsuranceRequestKeyParams {
  const [, insuranceRequestID] = queryKey;

  return { insuranceRequestID };
}
