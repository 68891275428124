import { useReducer } from 'react';
import { LoanApplicationContext } from './store/context';
import { LoanApplicationState, reducer } from './store/reducer';

type LoanApplicationProviderProps = {
  children: React.ReactNode;
  initialState: LoanApplicationState;
};

export function LoanApplicationProvider({
  children,
  initialState,
}: LoanApplicationProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LoanApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </LoanApplicationContext.Provider>
  );
}
