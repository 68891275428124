'use client';

import { FC, ReactNode, useEffect, useState } from 'react';
import { useIsDesktop } from '../../../hooks/useMediaQuery';
import StepperDesktop from '../StepperDesktop';
import StepperMobile from '../StepperMobile';

export type IStepperStepState =
  | 'untouched'
  | 'touched'
  | 'completed'
  | 'active';
export interface IStepperStep {
  title?: ReactNode;
  headline?: string;
  state: IStepperStepState;
  name: string;
}
export interface IStepper {
  steps: IStepperStep[];
  locale: string;
  hideHeadline?: boolean;
  completeApplicationLabel?: string;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Stepper: FC<IStepper> = (props) => {
  const [isClient, setIsClient] = useState(false);
  const [hasElementsWithTheme, setHasElementsWithTheme] = useState(false);

  const checkElementsWithTheme = () => {
    if (typeof document !== 'undefined') {
      const elementsWithTheme = document.querySelectorAll('[data-theme]');
      setHasElementsWithTheme(elementsWithTheme.length > 0);
    }
  };

  useEffect(() => {
    setIsClient(true);
    checkElementsWithTheme();
  }, []);

  const isDesktop = useIsDesktop() && !hasElementsWithTheme;

  if (!isClient) {
    // Render a placeholder or nothing until the client-side rendering is finished.
    return null;
  }

  return isDesktop ? (
    <StepperDesktop {...props} />
  ) : (
    <StepperMobile {...props} />
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Stepper;
