import { Checkbox } from '@axo/deprecated/util/ui-components';
import {
  ContentBoxWrapper,
  NavWrapper,
  NavigationButton,
  StepNavigator,
  ValidationContentBox,
} from '@axo/insurance/ui';
import { useTranslation } from '@axo/shared/i18n';
import { ReactNode, useRef, useState } from 'react';
import { getValidState } from '../../../StepProvider/getValidState';
import styles from './PowerOfAttorney.module.scss';

export const PowerOfAttorney = ({
  hasConfirmedPowerOfAttorney,
  data: { firstName, lastName, socialSecurityNumber },
  isEmbedded,
  onCancel,
  onNext,
  onBack,
  onChange,
}: {
  hasConfirmedPowerOfAttorney: boolean;
  data: {
    firstName: string;
    lastName: string;
    socialSecurityNumber?: string;
  };
  title: ReactNode | undefined;
  isEmbedded?: boolean;
  onCancel?: () => void;
  onNext: () => void;
  onBack: () => void;
  onChange: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [triedContinue, setTriedContinue] = useState(false);

  function handleBack() {
    onBack();
  }

  async function handleNext() {
    setTriedContinue(true);
    if (!hasConfirmedPowerOfAttorney) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    onNext();
  }

  const currentDate = new Date();

  return (
    <>
      <ContentBoxWrapper isEmbedded={isEmbedded}>
        <div className={styles.content}>
          <p>
            {t(
              'I {name} {surname}, I agree that Axo Loan may at any time charge my registered debit card to cover insurance premiums without a separate permit before each charge in accordance with the insurance certificate.',
              { name: firstName, surname: lastName }
            )}
          </p>
          <p>
            {t(
              'I guarantee that I am the debit card holder I register For Axo Loan.'
            )}
          </p>
          <p>
            {t(
              'Monthly charged amounts and insurance conditions appear in an insurance certificate or other notification and may vary by billing.'
            )}
          </p>
          <p>
            {t(
              'I am responsible for updating my information to Axo Loan Changes when appeared.'
            )}
          </p>
          <p>
            {t(
              'This proxy is valid from the proxy signature date for it until it is canceled in writing.'
            )}
          </p>

          <p>
            <span className={styles.label}>{t('Date')}:</span>{' '}
            {currentDate.getFullYear()} – {currentDate.getMonth() + 1} –{' '}
            {currentDate.getDate()}
          </p>
          <p>
            <span className={styles.label}>
              {t('Authorized person (cardholder)')}:
            </span>{' '}
            {firstName} {lastName}, {socialSecurityNumber}
          </p>
          <p>
            <span className={styles.label}>{t('Power of attorney')}:</span> Axo
            Finance Oy, 2984343-1 (“Axolaina”)
          </p>
          <ValidationContentBox
            active={hasConfirmedPowerOfAttorney}
            invalid={triedContinue && !hasConfirmedPowerOfAttorney}
          >
            <p className={styles.label}>{t('Proxy confirmation')}</p>
            <div ref={ref}>
              <Checkbox
                name="hasConfirmedPowerOfAttorney"
                onChange={(e) => onChange(e.target.checked)}
                errors={getValidState(
                  hasConfirmedPowerOfAttorney,
                  triedContinue,
                  t('To proceed, you must accept the terms') as string
                )}
                value={hasConfirmedPowerOfAttorney}
              >
                {t('I have read and accepted the above terms and proxy')}
              </Checkbox>
            </div>
          </ValidationContentBox>
        </div>
      </ContentBoxWrapper>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          leftButton={{
            width: 'standard',
            text: t('Go back') as string,
            variant: 'back',
            onClick: handleBack,
          }}
          rightButton={{
            width: 'standard',
            text: t('Confirm and pay') as string,
            variant: hasConfirmedPowerOfAttorney ? 'enabled' : 'disabled',
            onClick: handleNext,
          }}
        />
        {onCancel !== undefined && (
          <NavigationButton
            width="full-width"
            text={t('Continue without insurance') as string}
            variant="link"
            onClick={onCancel}
          />
        )}
      </NavWrapper>
    </>
  );
};
