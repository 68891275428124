import { ErrorModel } from '../../../models';
import classNames from '../../../utils/class-names';
import { Span } from '../StyledElements';
import styles from './ErrorText.module.scss';

export interface IErrorText {
  errors: ErrorModel[];
  align?: 'start' | 'end' | 'center';
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const ErrorText = (props: IErrorText) => {
  return (
    <div className={styles.errors} style={{ justifyContent: props.align }}>
      {props.errors &&
        props.errors.map((error, index) => (
          <Span
            key={index}
            className={classNames(styles.error, error.code && error.code)}
          >
            {error.message}
          </Span>
        ))}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default ErrorText;
