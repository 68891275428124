import { useEffect } from 'react';
import { Steps } from '../store/stepStates';

export const useSetInsuranceStepOnSearchParams = (
  selectedStep: keyof Steps
) => {
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);

    const currentStepOnSearchParams = currentSearchParams.get('insuranceStep');
    const currentStep = selectedStep as keyof Steps;

    if (currentStepOnSearchParams !== currentStep) {
      currentSearchParams.set('insuranceStep', selectedStep);

      const newUrl = `${
        window.location.pathname
      }?${currentSearchParams.toString()}`;

      window.history.pushState({}, '', newUrl);
    }
  }, [selectedStep]);
};
