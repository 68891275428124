import { insurance } from '@axo/shared/data-access/types';

export const parseInsuranceSource = (
  source: string | null
): insurance.InsuranceSourceTag | null => {
  if (source === null) {
    return null;
  }

  const int = parseInt(source, 10);
  const isInt = !isNaN(int);
  if (isInt)
    return (
      (insurance.InsuranceSourceTagEnum[int] as insurance.InsuranceSourceTag) ??
      null
    );

  if (Object.keys(insurance.InsuranceSourceTagEnum).includes(source))
    return source as insurance.InsuranceSourceTag;

  return null;
};

export const useInsuranceSourceTag =
  (): insurance.InsuranceSourceTag | null => {
    const source = new URLSearchParams(window.location.search).get('s');
    return parseInsuranceSource(source);
  };
