import { EventCode, useEventLogger } from '@axo/shared/data-access/event-log';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useState } from 'react';
import { useCreateInsuranceEvent } from '../InsurancePolicyProvider';
import { StepperState } from './store/reducer';

export const useInsuranceLogging = (state: StepperState) => {
  const log = useEventLogger();
  const [prev, setPrev] = useState<(typeof state)['selectedStep']>();
  const addEvent = useCreateInsuranceEvent();
  const { track } = useAnalytics();

  const isSummaryStep =
    state.selectedStep === 'terms' && state.steps.verifyPaymentCard.isCompleted;
  const selectedStep = isSummaryStep ? 'summary' : state.selectedStep;

  const trackEvents = async () => {
    if (prev === undefined) {
      await track({
        event: `Insurance Initiated`,
      });
    }

    if (selectedStep === 'summary') {
      await track({
        event: `Insurance Completed`,
        params: { amount: state.steps.selectCoverage.insurance.payout },
        options: {
          send_immediately: true,
        },
      });
    }

    addEvent('form/view-step', state.selectedStep);

    log(EventCode.ViewStep, { step: state.selectedStep });

    await track({
      event: `Insurance Step ${state.selectedStep} Viewed`,
      params: { step: state.selectedStep },
    });
  };

  if (state.selectedStep !== prev) {
    trackEvents();
    setPrev(state.selectedStep);
  }
};
