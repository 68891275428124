import classNames from '../../../utils/class-names';
import { IIcon, Icons } from '../Icons';
import styles from './Caret.module.scss';

export interface ICaret {
  color?: 'default' | 'white' | 'disabled' | 'dark';
  direction?: 'left' | 'right' | 'up' | 'down';
  iconProps?: Partial<IIcon>;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Caret: React.FunctionComponent<ICaret> = ({
  color = 'default',
  direction,
  iconProps,
}: ICaret) => {
  return (
    <Icons.Caret
      {...iconProps}
      classes={{
        root: classNames(
          styles.caret,
          styles['style-' + color],
          direction ? styles['direction-' + direction] : ''
        ),
      }}
    />
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Caret;
