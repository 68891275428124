import {
  IPersonData,
  InsuranceContext,
  StepContext,
} from '@axo/insurance/feature/providers';
import {
  useInsurancePolicy,
  usePatchPerson,
  usePerson,
} from '@axo/shared/data-access/hooks';
import { useContext, useEffect } from 'react';

export const usePersonData = () => {
  const { onError } = useContext(StepContext);
  const { state: insuranceState } = useContext(InsuranceContext);
  const insurancePolicy = useInsurancePolicy(
    insuranceState.insurancePolicyReference?.PolicyID ?? undefined
  );
  const person = usePerson(insurancePolicy.data?.PersonID);
  const patchPerson = usePatchPerson();

  const hasData: { [Property in keyof IPersonData]: boolean } = {
    address: person.data?.HasCompleteAddress ?? false,
    name: person.data?.HasCompleteName ?? false,
  };

  useEffect(() => {
    if (person.isError) {
      onError(new Error('Error while fetching person data'));
    }
  }, [onError, person.isError]);

  return {
    data: person.data,
    patchPerson,
    isSuccess: person.isSuccess,
    hasData,
  };
};
