import { ForwardRefRenderFunction, ReactNode, forwardRef } from 'react';
import { IInputProps } from '../../models/input.model';
import clsx from 'clsx';
import styles from './Radio.module.scss';

export type IRadioStyle = 'radio' | 'button' | 'both';

type Severity = 'warning';

export interface IRadio extends IInputProps<HTMLInputElement, string> {
  radioStyle?: IRadioStyle;
  variant?: 'default' | 'warning';
  checked?: boolean;
  severity?: Severity;
  id: string;
  classes?: ClassNamesProp;
  children?: ReactNode;
  buttonHeight?: 'large' | 'regular';
  label?: string;
}

type ClassNamesProp = {
  root?: string;
};

export const Radio: ForwardRefRenderFunction<HTMLInputElement, IRadio> = (
  {
    radioStyle = 'radio',
    id,
    children,
    classes,
    severity,
    buttonHeight,
    variant = 'default',
    label,
    ...props
  },
  ref
) => (
  <div className={clsx(styles['radio-wrapper'], classes?.root)}>
    <input
      className={clsx(
        styles.radio,
        variant === 'default' ? styles.success : styles.warning,
        children ? styles[radioStyle] : '',
        severity && styles[severity],
        buttonHeight === 'large' && styles['large']
      )}
      type="radio"
      {...props}
      ref={ref}
      aria-label={label}
    />
    {children && <label htmlFor={`radio-${id}`}>{children}</label>}
  </div>
);

export default forwardRef(Radio);
