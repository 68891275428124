export const timeoutAfter = async <T>(
  promise: Promise<T>,
  timeout = 2000,
  error = 'Operation timed out'
): Promise<T> => {
  let timeoutId: number | NodeJS.Timeout;
  const timeoutPromise = new Promise<T>((_, reject) => {
    timeoutId = setTimeout(() => reject(new Error(error)), timeout);
  });

  return Promise.race([promise, timeoutPromise])
    .then((value) => {
      clearTimeout(timeoutId);
      return value;
    })
    .catch((reason) => {
      clearTimeout(timeoutId);
      throw reason;
    });
};
