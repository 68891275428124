/* eslint-disable axo-frontend/directory-structure */
import {
  InsuranceContext,
  InsuranceState,
  insuranceInitialState,
  reducer,
} from '@axo/insurance/feature/providers';
import { PageSpinner } from '@axo/insurance/ui';
import { insurance } from '@axo/shared/data-access/types';
import { TranslationProvider, addTranslationBundle } from '@axo/shared/i18n';
import { ReactNode, useReducer } from 'react';
import InsuranceTranslations from '../locales/fi.Insurance.i18n.json';
import { InsuranceContent } from './InsuranceContent';

addTranslationBundle({
  Insurance: { fi: InsuranceTranslations },
});

export type OnEnd = {
  action: (params?: { token?: string; uid?: string }) => void;
  summaryButtonText?: string;
};

export type InsuranceProviderProps = {
  children: ReactNode;
  onEnd: OnEnd;
  spinner?: ReactNode;
  sourceTag: insurance.InsuranceSourceTag;
  initialState?: InsuranceState;
};

export function InsuranceProvider({
  children,
  onEnd,
  spinner = <PageSpinner />,
  sourceTag,
  initialState = insuranceInitialState,
}: InsuranceProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <InsuranceContext.Provider value={{ state, dispatch }}>
      <TranslationProvider language="fi" ns="Insurance">
        <InsuranceContent {...{ children, onEnd, spinner, sourceTag }} />
      </TranslationProvider>
    </InsuranceContext.Provider>
  );
}
