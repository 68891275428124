import { FC, ReactNode } from 'react';
import { ICSSColorVariable } from '../../../models/color.model';
import styles from './IconWrapper.module.scss';

// Add icon SVGs in the Icons file, and it will be available for use with the Icon component.

export type SizeKeys = keyof typeof sizeToCssClassesMap;

const sizeToCssClassesMap = {
  xxs: 'iconWrapper--xxs',
  xs: 'iconWrapper--xs',
  sm: 'iconWrapper--sm',
  md: 'iconWrapper--md',
  lg: 'iconWrapper--lg',
  xlg: 'iconWrapper--xlg',
  full: 'iconWrapper--full',
};

const rotationToClassesMap = {
  '0': 'rotate-0',
  '90': 'rotate-90',
  '180': 'rotate-180',
  '270': 'rotate-270',
};

export type RotationKeys = keyof typeof rotationToClassesMap;

enum ClassNames {
  Root = 'root',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export type IconColorVariable = ICSSColorVariable | 'none' | any;

export type IIconColor = {
  primary?: IconColorVariable;
  secondary?: IconColorVariable;
};

export type IIcon = {
  size?: SizeKeys;
  classes?: ClassNamesProp;
  children?: ReactNode;
  color?: IIconColor;
  rotation?: RotationKeys;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const IconWrapper: FC<IIcon> = ({
  size = 'md',
  classes = { root: '' },
  children,
  rotation = '0',
}: IIcon) => {
  return (
    <div
      className={`${styles[sizeToCssClassesMap[size]]} ${classes?.root} ${
        styles[rotationToClassesMap[rotation]]
      }`}
    >
      {children}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default IconWrapper;
