const createConfig = <T = Record<string, string | undefined>>(
  appName: string
) => {
  let config: T | undefined = undefined;

  return {
    configure(environmentVariables: T) {
      config = environmentVariables;
    },
    getConfig(): T {
      if (!config)
        throw new Error(
          `Missing configuration. Did you remember to configure ${appName}?`
        );
      return config;
    },
  };
};

export interface IEnv {
  readonly ENV: string;
  readonly API_URL: string;
  readonly ASSENTLY_URL: string;
}

const { configure, getConfig } = createConfig<IEnv>('UI Components');

const ignoreError = <T>(fn: () => T): T | undefined => {
  try {
    return fn();
  } catch (e) {
    return undefined;
  }
};

interface ImportMeta {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly env: any;
}

/**
 * In CRA and vite apps, this should automatically configure the ui components,
 * as long as the environment variable names match. Otherwise call
 * configureUIComponents to configure it explicitly.
 */
configure({
  API_URL:
    ignoreError(() => process.env.REACT_APP_API_URL) ??
    ignoreError(() => (import.meta as unknown as ImportMeta).env.VITE_API_URL),
  ENV:
    ignoreError(() => process.env.REACT_APP_ENV) ??
    ignoreError(() => (import.meta as unknown as ImportMeta).env.VITE_ENV),
  ASSENTLY_URL:
    ignoreError(() => process.env.REACT_APP_ASSENTLY_URL) ??
    ignoreError(
      () => (import.meta as unknown as ImportMeta).env.VITE_ASSENTLY_URL
    ),
});

export { configure as configureUIComponents, getConfig as getLibConfig };
