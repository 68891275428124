import { ReactNode, useEffect, useState } from 'react';
import { ITranslationContext, TranslationContext } from './context';
import { i18n } from './instance';

export type ITranslationProvider = {
  children: ReactNode;
} & ITranslationContext;

export function TranslationProvider({
  children,
  language,
  ns,
}: ITranslationProvider) {
  const [displayLanguage, setDisplayLanguage] = useState('');

  useEffect(() => {
    if (language !== displayLanguage) {
      i18n.changeLanguage(language);
      setDisplayLanguage(language);
    }

  }, [language])

  return (
    <TranslationContext.Provider value={{ language, ns }}>
      {children}
    </TranslationContext.Provider>
  );
}
