import { IAnalyticsService } from './AnalyticsService.types';
import { ClarityService } from './clarity/ClarityService';
import { GoogleTagService } from './google-tag/GoogleTagService';
import { LaunchDarklyService } from './launch-darkly/LaunchDarklyService';
import { MixpanelService } from './mixpanel/MixpanelService';
import { SentryService } from './sentry/SentryService';

export type AnalyticsServices =
  | 'gtag'
  | 'launchDarkly'
  | 'mixpanel'
  | 'clarity'
  | 'sentry';

export const AnalyticsServicesRegistry: Record<
  AnalyticsServices,
  IAnalyticsService
> = {
  gtag: GoogleTagService,
  launchDarkly: LaunchDarklyService,
  mixpanel: MixpanelService,
  clarity: ClarityService,
  sentry: SentryService,
};
