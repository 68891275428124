import { i18n } from './instance';
import { Locales } from './types';

type ComponentTranslation = {
  [key in Locales]?: {
    [key: string]:
      | string
      | string[]
      | { [key: string]: string }
      | ComponentTranslation[Locales];
  };
};

export function addTranslationBundle(
  namespaces: Record<string, ComponentTranslation> = {}
) {
  for (const [namespace, locales] of Object.entries(namespaces)) {
    for (const [locale, translations] of Object.entries(locales)) {
      if (!i18n.hasResourceBundle(locale, namespace)) {
        i18n.addResourceBundle(locale, namespace, translations, true, true);
      }
    }
  }
}
