import { insurance_meta } from '@axo/shared/data-access/types';

export async function createEvent(
  baseURL: string,
  token: string,
  create: insurance_meta.CreateEventReq
) {
  const url = new URL(`/insurance-meta/event`, baseURL);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(create),
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
}
