'use client';

import RCSlider from 'rc-slider';
import styles from './Slider.module.scss';
import { getFormattedAmount } from '../../../formatters/numbers';
import { useEffect, useState } from 'react';
import classNames from '../../../utils/class-names';
import { Span } from '../../atoms/StyledElements';
// It is not possible to access the Slider components classes with css modules.

export type ISlider = {
  min: number;
  minSuffix?: string;
  max: number;
  maxSuffix?: string;
  step?: number;
  value: number;
  label?: string;
  valueSuffix?: string;
  onChange?: (value: number) => void;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  reverseHeaderOrder?: boolean;
  hideLegend?: boolean;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Slider = ({
  min,
  minSuffix,
  max,
  maxSuffix,
  step,
  value,
  label,
  valueSuffix = '',
  onChange,
  onFocus,
  onBlur,
  reverseHeaderOrder = false,
  hideLegend = false,
}: ISlider): JSX.Element => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={styles.container}>
      {label && (
        <div
          className={classNames(
            styles.headers,
            reverseHeaderOrder ? styles.reverseHeaderOrder : ''
          )}
        >
          <Span className={styles.bold}>{label}</Span>
          <Span className={styles.bold}>{`${getFormattedAmount(
            value
          )} ${valueSuffix}`}</Span>
        </div>
      )}

      <RCSlider
        min={min}
        max={max}
        step={step}
        value={localValue}
        className={styles['rc-slider']}
        onChange={(v) => {
          onChange?.(v as number);
          setLocalValue(v as number);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {!hideLegend && (
        <div className={styles.sliderLegend}>
          <Span>{`${getFormattedAmount(min)} ${
            minSuffix ?? valueSuffix
          }`}</Span>
          <Span>{`${getFormattedAmount(max)} ${
            maxSuffix ?? valueSuffix
          }`}</Span>
        </div>
      )}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Slider;
