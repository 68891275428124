import {
  StepperState,
  getInitialStepperState,
} from '@axo/insurance/feature/providers';
import { i18n } from '@axo/shared/i18n';

// ideally this should not be in state at all, but instead in relevant components.
const t = i18n.getFixedT('fi', 'Insurance');

export const getInitialState = (): StepperState => getInitialStepperState({
  error: undefined,
  insurance: undefined,
  insurancePolicy: undefined,
  insurancePayment: undefined,
  selectedStep: 'explainer',
  steps: {
    explainer: {
      name: 'explainer',
      title: t('Credit card details'),
      state: 'active',
      isShownInStepper: true,
      isHeadlineShown: false,
    },
    powerOfAttorney: {
      name: 'powerOfAttorney',
      title: t('TermsOfPayment'),
      state: 'untouched',
      hasConfirmedPowerOfAttorney: false,
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    verifyPaymentCard: {
      name: 'verifyPaymentCard',
      title: t('Accept and pay'),
      state: 'untouched',
      payment: null,
      paymentStateKey: null,
      completeStateKey: null,
      isCompleted: false,
      isShownInStepper: true,
      isHeadlineShown: true,
    },
  },
});
