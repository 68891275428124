import { person } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function getPerson({ url: { service: baseUrl }, token }: IAPI) {
  return async function getPerson(id: string): Promise<person.Person> {
    const url = new URL(`/person/${id}`, baseUrl);

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}

export function postPerson({ url: { service: baseUrl }, token }: IAPI) {
  return async function postPerson(
    person: person.Person
  ): Promise<person.Person> {
    const url = new URL(`/person`, baseUrl);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(person),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error posting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}

export function patchPerson({ url: { service: baseUrl }, token }: IAPI) {
  return async function patchPerson(
    id: string,
    person: person.UpdatePerson
  ): Promise<person.Person> {
    const url = new URL(`/person/${id}`, baseUrl);

    const response = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(person),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error patching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };
}
