// Why not use the native Int.NumberFormat constructor? Because we want more
// control. Specifically, we are using spaces for thousands separators amd that
// doesn't align with e.g. Swedish locale.
export const currencyFormatter = (amount = 0, currency = '€', decimals = 0) => {
  const formatted = amount
    .toFixed(decimals) // Fix the number of decimals
    .replace(/\./g, ',') // Replace dot with comma for the decimal part
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Add space as thousand separator

  return `${formatted} ${currency}`;
};
