export function getValidState(
  value: boolean,
  triedSubmit: boolean,
  message: string
) {
  if (value) return undefined;
  if (triedSubmit && !value) return [{ message: message }];

  return undefined;
}
