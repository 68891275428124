import { useContext, useEffect, useMemo, useState } from 'react';
import { usePersonData } from './usePersonData';
import { useEventField } from '@axo/shared/data-access/event-log';
import {
  IAddress,
  IName,
  IPersonData,
  StepContext,
} from '@axo/insurance/feature/providers';

export const usePersonDataForm = () => {
  const { dispatch, state } = useContext(StepContext);
  const [continueAttempts, setContinueAttempts] = useState(0);
  const [formData, setFormData] = useState<IPersonData>(
    state.steps.selectCoverage.personData
  );
  const { data, patchPerson, isSuccess, hasData } = usePersonData();
  const isInputValid = (key: keyof IPersonData) => {
    return Object.values(formData[key]).reduce((accumulator, currentValue) => {
      return accumulator && currentValue.length > 0;
    }, true);
  };
  const getIsFormInputValid = () => {
    return Object.keys(formData).reduce((accumulator, currentValue) => {
      const key = currentValue as keyof IPersonData;
      return accumulator && (isInputValid(key) || hasData[key]);
    }, true);
  };
  const [isFormInputValid, setIsFormInputValid] = useState(
    getIsFormInputValid()
  );
  const [hasChanged, setHasChanged] = useState<{
    [Property in keyof IPersonData]: boolean;
  }>({
    name: false,
    address: false,
  });

  useEventField('has-address', hasData.address.toString());
  useEventField('has-name', hasData.name.toString());

  const isFormShown = state.steps.selectCoverage.isFormShown;

  useEffect(() => {
    const getFormsToShow = () => {
      return Object.entries(hasData).reduce((accumulator, [key, value]) => {
        if (isFormShown[key as keyof IPersonData]) return accumulator;
        if (value === true) return accumulator;
        return {
          ...accumulator,
          [key]: true,
        };
      }, {});
    };

    if (!isSuccess) return;
    const formsToShow = getFormsToShow();
    if (Object.keys(formsToShow).length === 0) return;
    dispatch({
      type: 'Set step data',
      scope: { parentType: 'selectCoverage' },
      payload: {
        isFormShown: {
          ...isFormShown,
          ...formsToShow,
        },
      },
    });
  }, [dispatch, hasData, isFormShown, isSuccess]);

  const handleInputChange = (
    key: keyof IPersonData,
    value: Partial<IPersonData[typeof key]>
  ) => {
    const validateFormInput = () => {
      setIsFormInputValid(getIsFormInputValid());
    };

    setHasChanged((values) => {
      values[key] = true;
      return values;
    });
    setFormData((values) => {
      values[key] = { ...values[key], ...value } as IName & IAddress;
      return values;
    });
    validateFormInput();
  };

  const shouldPatch = (key: keyof IPersonData) => {
    return isInputValid(key) && hasChanged[key];
  };

  const getPatchData = () => {
    return Object.entries(formData).reduce((accumulator, [key, value]) => {
      if (shouldPatch(key as keyof IPersonData))
        return {
          ...accumulator,
          ...value,
        };
      return accumulator;
    }, {});
  };

  const submitPersonData = () => {
    if (data === undefined || data.ID === undefined) {
      return;
    }
    const patchData = getPatchData();
    if (Object.keys(patchData).length === 0) return;
    patchPerson.mutate({
      id: data.ID,
      data: {
        ...data,
        ...patchData,
      },
    });
  };

  const isAnyFormShown = useMemo(() => {
    return Object.values(isFormShown).reduce((previousValue, currentValue) => {
      return previousValue || currentValue;
    }, false);
  }, [isFormShown]);

  return {
    isFormResolved: isSuccess && (isFormInputValid || !isAnyFormShown),
    submitPersonData,
    handleInputChange,
    incrementContinueAttemptCounter: () =>
      setContinueAttempts((count) => count + 1),
    formData,
    continueAttempts,
  };
};
