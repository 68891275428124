'use client';

import {
  gtag,
  gtagIdentify,
} from '@axo/shared/services/google-tag-manager/gtag';
import {
  AnalyticsEvent,
  AnalyticsIdentify,
  IAnalytics,
} from '../Analytics.types';
import {
  IAnalyticsService,
  IAnalyticsServiceParams,
} from '../AnalyticsService.types';
import { timeoutAfter } from '../utils/timeoutAfter';
import { waitUntil } from '../utils/waitUntil';

/**
 * Google tag Service
 *
 * @see https://developers.google.com/tag-platform/gtagjs
 */
export const GoogleTagService: IAnalyticsService = (
  params: IAnalyticsServiceParams = {}
): IAnalytics => {
  const { timeout = 2000 } = params;
  const hasServicePresent = !!document?.getElementById('google-tag-manager');

  if (!hasServicePresent) {
    console.log('GoogleTagService google-tag-manager script id not found');
  }

  const waitForInitialization = () => waitUntil(isInitialized);
  const isInitialized = () =>
    window?.dataLayer?.some(
      (item) => item['gtm.start'] !== undefined && item['event'] === 'gtm.js'
    ) || false;

  const track = async (e: AnalyticsEvent) => {
    const promise = new Promise<void>((resolve, reject) => {
      // the right arrow suffix is used to make it easier to filter events in GTM / GA
      gtag('event', `${e.event} →`, {
        ...e.params,
        event_callback: resolve,
        send_to: params.id,
      });
      // don't wait for callback if service is not present
      if (!hasServicePresent)
        reject(new Error('GoogleTagService service is not present'));
    });

    return timeoutAfter(
      promise,
      timeout, // for ad blockers that prevent loading gtag (thus no event callback)
      'GoogleTagService timed out'
    );
  };

  /**
   * @see https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag
   */
  const identify = async ({ uuid, email, phonenumber }: AnalyticsIdentify) => {
    const promise = new Promise<void>((resolve, reject) => {
      gtagIdentify({
        user_id: uuid,
        email,
        phonenumber,
      });
      // don't wait for callback if service is not present
      if (!hasServicePresent)
        reject(new Error('GoogleTagService service is not present'));
    });

    return timeoutAfter(
      promise,
      timeout, // for ad blockers that prevent loading gtag (thus no event callback)
      'GoogleTagService timed out'
    );
  };

  return {
    waitForInitialization,
    isInitialized,
    track,
    identify,
  };
};
