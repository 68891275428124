import { insurance_request } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function createInsuranceRequest({
  url: { service: baseURL },
  token,
}: IAPI) {
  return async function createInsuranceRequest(
    create: insurance_request.Create
  ) {
    const url = new URL(`/insurance-request`, baseURL);
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(create),
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_request.Request;
  };
}

export function getInsuranceRequest({
  url: { service: baseURL },
  token,
}: IAPI) {
  return async function getInsuranceRequest(id: string) {
    const url = new URL(`/insurance-request/${id}`, baseURL);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_request.Request;
  };
}
