import { useContext } from 'react';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { insurance_meta_api } from '@axo/shared/data-access/api';
import { insurance_meta } from '@axo/shared/data-access/types';
import { useMutation } from 'react-query';

export function useCreateEvent() {
  const { state, url } = useContext(DataAccessContext);

  return useMutation({
    mutationFn: (params: insurance_meta.CreateEventReq) =>
      insurance_meta_api.createEvent(url.api, state.user.token, params),
    onError: () => {
      // ignore error
      return;
    },
  });
}
