import { CrossDomainAuthData } from '../CrossDomainAuth.types';

type CreateAuthRouteProps = {
  baseUrl: string | URL;
  data: CrossDomainAuthData;
};

export const createCrossDomainAuthRoute = ({
  baseUrl,
  data,
}: CreateAuthRouteProps): URL => {
  const url = typeof baseUrl === 'string' ? new URL(baseUrl) : baseUrl;

  if (data.applicationId) url.searchParams.set('id', data.applicationId);

  if (data.token) url.hash = `#token=${data.token}`;

  return url;
};
