'use client';

import { storeCrossDomainAuth } from './store/store-cross-domain-auth';
import { createCrossDomainAuthRoute } from './route/create-cross-domain-auth-route';
import { retrieveCrossDomainAuth } from './store/retrieve-cross-domain-auth';
import { useCallback } from 'react';
import { CrossDomainAuthData } from './CrossDomainAuth.types';
import { clearCrossDomainAuthRouteToken } from './route/clear-cross-domain-auth-route-token';
import { retrieveCrossDomainAuthRouteData } from './route/retrieve-cross-domain-auth-route-data';

type CreateAuthRouteConfig = {
  baseUrl: string | URL;
  data?: CrossDomainAuthData;
};

type RetrieveAuthRouteConfig = {
  shouldClear?: boolean;
  shouldStore?: boolean;
};

export const useCrossDomainAuthRoute = () => {
  const createAuthRoute = useCallback(
    ({ baseUrl, data }: CreateAuthRouteConfig): URL => {
      const authData = data ?? retrieveCrossDomainAuth();
      return createCrossDomainAuthRoute({ baseUrl, data: authData });
    },
    []
  );

  const retrieveAuthDataFromRoute = useCallback(
    ({
      shouldClear = false,
      shouldStore = false,
    }: RetrieveAuthRouteConfig = {}): CrossDomainAuthData => {
      const authData = retrieveCrossDomainAuthRouteData();
      if (shouldClear) clearCrossDomainAuthRouteToken();
      if (shouldStore) storeCrossDomainAuth(authData);
      return authData;
    },
    []
  );

  return {
    createAuthRoute,
    retrieveAuthDataFromRoute,
    clearAuthData: clearCrossDomainAuthRouteToken,
  };
};
