import { Icons } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import styles from './Drawer.module.scss';

interface IDrawer {
  label: React.ReactNode | string;
  details: React.ReactNode | string;
  classes?: ClassNamesProp;
}

type ClassNamesProp = { root?: string; label?: string; details?: string };

export function Drawer({ label, details, classes }: IDrawer) {
  return (
    <div className={classNames(styles.drawerContainer, classes?.root)}>
      <details className={classes?.details}>
        <summary className={classNames(styles.label, classes?.label)}>
          {label}
          <div className={styles.caret}>
            <Icons.Chevron size="xs" />
          </div>
        </summary>
        {details}
      </details>
    </div>
  );
}
