export interface IInsurancePaymentKeyParams {
  insurancePaymentID: string;
}

export const insurancePaymentKeys = {
  root: (params: IInsurancePaymentKeyParams) =>
    ['insurance payment', params.insurancePaymentID] as const,
};

export function parseInsurancePaymentKey<
  T extends keyof typeof insurancePaymentKeys
>(
  queryKey: ReturnType<(typeof insurancePaymentKeys)[T]>
): IInsurancePaymentKeyParams {
  const [, insurancePaymentID] = queryKey;

  return { insurancePaymentID };
}
