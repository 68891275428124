import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
  useMemo,
} from 'react';
import { IVariant } from '@axo/deprecated/util/ui-components';
import { getRandomString } from '@axo/shared/util/string';

export interface ToastElements {
  header?: ReactNode;
  content?: ReactNode;
  variety?: IVariant;
}

export interface Toast {
  id: string;
  elements: ToastElements;
}

export type State = Readonly<Toast[]>;
const initialState: State = [];

export type Action =
  | {
      type: 'toast/display';
      payload: ToastElements;
    }
  | { type: 'toast/dismiss'; payload: string };

const ToastContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => {
    throw new Error(
      'Dispatch function cannot be used outside of ToastProvider'
    );
  },
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'toast/display':
      return [
        ...state,
        {
          id: getRandomString(),
          elements: action.payload,
        },
      ];

    case 'toast/dismiss':
      return state.filter((toast) => toast.id !== action.payload);

    default:
      return state;
  }
};

type Props = {
  children: ReactNode;
};

export const ToastProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastState = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastState must be used within a ToastProvider');
  }
  return context.state;
};

export const useToastActions = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastActions must be used within a ToastProvider');
  }

  const displayToast = useCallback(
    (elements: ToastElements) => {
      context.dispatch({
        type: 'toast/display',
        payload: elements,
      });
    },
    [context]
  );

  const dismissToast = useCallback(
    (id: string) => {
      context.dispatch({ type: 'toast/dismiss', payload: id });
    },
    [context]
  );

  return { displayToast, dismissToast };
};
