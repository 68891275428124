export const payoutAndPremium = [
  { payout: 0, premium: 0 },
  { payout: 2000, premium: 129 },
  { payout: 3000, premium: 169 },
  { payout: 4000, premium: 209 },
  { payout: 5000, premium: 279 },
  { payout: 6000, premium: 319 },
  { payout: 7000, premium: 359 },
  { payout: 8000, premium: 409 },
  { payout: 9000, premium: 449 },
  { payout: 10000, premium: 499 },
] as const;

export type IPayOutAndPremium = (typeof payoutAndPremium)[number];
