import { insurance_payment } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function createCardMandate({ url: { service: baseURL }, token }: IAPI) {
  return async function createCardMandate(
    request: insurance_payment.CreatePaymentRequest
  ) {
    const url = new URL(`/insurance-payment-mandate/card`, baseURL);
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.Payment;
  };
}

export function createKidMandate({ url: { service: baseURL }, token }: IAPI) {
  return async function createKidMandate(
    request: insurance_payment.CreatePaymentMandateRequest
  ) {
    const url = new URL(`/insurance-payment-mandate/kid`, baseURL);
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.Payment;
  };
}

export function createBsMandate({ url: { service: baseURL }, token }: IAPI) {
  return async function createKidMandate(
    request: insurance_payment.CreateBsPaymentMandateRequest
  ) {
    const url = new URL(`/insurance-payment-mandate/bs`, baseURL);
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.Payment;
  };
}

export function findCardMandates({ url: { service: baseURL }, token }: IAPI) {
  return async function find(request: insurance_payment.FindPaymentInput) {
    const url = new URL(`/insurance-payment-mandate/card`, baseURL);
    for (const [key, value] of Object.entries(request)) {
      url.searchParams.set(key, value);
    }
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.FindPaymentResponse;
  };
}

export function findKidMandates({ url: { service: baseURL }, token }: IAPI) {
  return async function findKidMandates(
    request: insurance_payment.FindPaymentInput
  ) {
    const url = new URL(`/insurance-payment-mandate/kid`, baseURL);
    for (const [key, value] of Object.entries(request)) {
      url.searchParams.set(key, value);
    }
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.FindPaymentResponse;
  };
}

export function getCardMandate({ url: { service: baseURL }, token }: IAPI) {
  return async function getCardMandate(id: string) {
    const url = new URL(`/insurance-payment-mandate/card/${id}`, baseURL);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.Payment;
  };
}

export function getKidMandate({ url: { service: baseURL }, token }: IAPI) {
  return async function getKidMandate(id: string) {
    const url = new URL(`/insurance-payment-mandate/kid/${id}`, baseURL);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as insurance_payment.Payment;
  };
}
