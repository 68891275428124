import { useState, useEffect, useMemo } from 'react';
import { throttleFunction } from '../utils/throttle';

const useMediaQuery = (query: string) => {
  const [hasMountedClientSide, setHasMountedClientSide] = useState(false);

  const [matches, setMatches] = useState(false);

  const hasWindow = !!typeof window;
  useEffect(() => {
    if (!hasMountedClientSide && typeof window !== 'undefined') {
      setHasMountedClientSide(true);
      setMatches(window.matchMedia(query).matches);
    }
  }, [hasMountedClientSide, hasWindow, query]);

  const listener = useMemo(
    () =>
      throttleFunction(() => {
        if (!hasMountedClientSide) return;
        const media = window.matchMedia(query);
        setMatches(media.matches);
      }),
    [hasMountedClientSide, query]
  );

  useEffect(() => {
    if (!hasMountedClientSide) return;
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener, hasMountedClientSide]);

  return matches;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default useMediaQuery;

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const useIsDesktop = () => useMediaQuery('(min-width: 960px)');
