export const payoutAndPremium = [
  { payout: 0, premium: 0 },
  { payout: 200, premium: 17.49 },
  { payout: 300, premium: 22.49 },
  { payout: 400, premium: 27.49 },
  { payout: 500, premium: 35.49 },
  { payout: 600, premium: 40.99 },
  { payout: 700, premium: 46.49 },
  { payout: 800, premium: 51.99 },
  { payout: 900, premium: 57.99 },
  { payout: 1000, premium: 63.49 },
] as const;

export type IPayOutAndPremium = (typeof payoutAndPremium)[number];
