import { ReactElement, ReactNode, RefObject } from 'react';

import classNames from '../../../utils/class-names';
import LoadingSpinner from '../LoadingSpinner';
import { Span } from '../StyledElements';

import styles from './Button.module.scss';

export type IButtonVariant =
  | 'secondary'
  | 'primary'
  | 'primary-inverted'
  | 'edit'
  | 'confirm'
  | 'white'
  | 'primary-sweden'
  | 'success'
  | 'ghost'
  | 'tertiary';

export type AnimationVariants =
  | 'none'
  | 'ping'
  | 'shadow'
  | 'bounce'
  | 'wobble';

type ClassNamesProp = { root?: string };

export interface IButton
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'size'> {
  variant?: IButtonVariant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  width?: 'standard' | 'full-width';
  loading?: boolean;
  type?: 'button' | 'submit';
  classes?: ClassNamesProp;
  fontVariant?: 'primary' | 'secondary';
  size?: 'small' | 'regular' | 'large';
  animation?: AnimationVariants;
  forwardRef?: RefObject<HTMLButtonElement>;
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export function Button({
  children,
  variant = 'secondary',
  onClick,
  startIcon,
  endIcon,
  width,
  disabled,
  loading,
  type = 'button',
  classes,
  id,
  fontVariant = 'primary',
  size,
  animation = 'none',
  forwardRef,
  className,
  ...props
}: IButton): ReactElement {
  return (
    <button
      ref={forwardRef}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      id={id}
      className={classNames(
        'Button',
        styles.Button,
        styles[variant],
        size === 'large' && styles.large,
        size === 'small' && styles.small,
        width === 'full-width' && styles['full-width'],
        loading && styles.loading,
        styles[`font-${fontVariant}`],
        classes?.root,
        className
      )}
      data-animation={animation}
      {...props}
    >
      {loading && (
        <div className={styles.spinnerWrapper}>
          <LoadingSpinner colorVariable="--light-100" />
        </div>
      )}
      {startIcon && <Span className={styles.start}>{startIcon}</Span>}
      <span>{children}</span>
      {endIcon && <Span className={styles.end}>{endIcon}</Span>}
    </button>
  );
}

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Button;
