export interface IMarketCountryKeyParams {
  marketCountry: string;
}

export const marketCountryKeys = {
  root: (params: IMarketCountryKeyParams) =>
    ['market country', params.marketCountry] as const,
  banks: (params: IMarketCountryKeyParams) =>
    [...marketCountryKeys.root(params), 'banks'] as const,
  heartbeats: (params: IMarketCountryKeyParams) =>
    [...marketCountryKeys.root(params), 'heartbeats'] as const,
};

export function parseMarketCountryKey<T extends keyof typeof marketCountryKeys>(
  queryKey: ReturnType<(typeof marketCountryKeys)[T]>
) {
  const [, marketCountry] = queryKey;

  return { marketCountry };
}
