import { customer } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function get({ url: { service: baseUrl }, token }: IAPI) {
  return async function getCustomer(id: string): Promise<customer.Customer> {
    const url = new URL(`/customer/${id}`, baseUrl);
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch {
      throw new Error('Failed to fetch customer');
    }
  };
}

export function findByEmail({ url: { service: baseUrl }, token }: IAPI) {
  return async function findByEmail(
    email: string
  ): Promise<customer.Customer[]> {
    const url = new URL(
      `/customer?email=${encodeURIComponent(email)}`,
      baseUrl
    );
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch {
      throw new Error('Failed to fetch customer');
    }
  };
}

export async function postCustomer(
  baseUrl: string,
  customer: customer.CreateCustomer
): Promise<customer.CreateCustomer> {
  const url = new URL(`/customer?accepted_terms`, baseUrl);

  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(customer),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch {
    throw new Error('Failed to post customer');
  }
}

export function patchCustomer({ url: { service: baseUrl }, token }: IAPI) {
  return async function patchCustomer(
    id: string,
    customer: customer.UpdateCustomer
  ): Promise<customer.Customer> {
    const url = new URL(`/customer/${id}`, baseUrl);
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(customer),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch {
      throw new Error('Failed to patch customer');
    }
  };
}

export function revokeMarketingConsent({
  url: { service: baseUrl },
  token,
}: IAPI) {
  return async function revokeMarketingConsent(
    customerID: string
  ): Promise<customer.Customer> {
    const url = new URL(`/customer/${customerID}/consent`, baseUrl);
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch {
      throw new Error('Failed to patch customer');
    }
  };
}
