import { AbstractFormatter } from '@axo/shared/util/string';
import { MutableRefObject } from 'react';

export class FormattedInputCursorController {
  private previousCursorPosition = 0;
  private previousCursorOffset = 0;
  private suffixLength = 0;
  private formatter?: AbstractFormatter;

  constructor(
    private readonly ref: MutableRefObject<HTMLInputElement | null>
  ) {}

  setFormatter(formatter: AbstractFormatter) {
    this.formatter = formatter;
  }

  setSuffixLength(suffixLength: number) {
    this.suffixLength = suffixLength;
  }

  afterInputValueChange() {
    if (!this.ref || !this.ref.current || !this.formatter) return;

    const cursorPosition = this.getCurrentCursorPosition();
    const cursorOffset =
      this.formatter.getOffsetAtCursorPosition(cursorPosition);

    // We only want to deal with the cases when the input value has a character added or removed.
    // By only looking at the cases where the cursor has moved a single character, we ignore
    // cases such as when multiple characters are pasted or the value length changes because of formatting.
    const positionChangedMaxOneStep =
      Math.abs(this.previousCursorPosition - cursorPosition) <= 1;

    if (
      positionChangedMaxOneStep &&
      cursorOffset !== this.previousCursorOffset
    ) {
      const cursorOffsetChange = cursorOffset - this.previousCursorOffset;
      this.setCursorPosition(
        this.getCurrentCursorPosition() + cursorOffsetChange
      );
    }

    this.storeCursorState();
  }

  afterCursorMove() {
    if (!this.ref || !this.ref.current || !this.formatter) return;

    const cursorPosition = this.getCurrentCursorPosition();

    if (!this.isWithinBounds(cursorPosition)) {
      this.setCursorPosition(this.getEndBoundary());
    } else if (this.formatter.hasNoPlaceholderAt(cursorPosition - 1)) {
      const moveDirection = this.getCursorMoveDirection(cursorPosition);
      this.setCursorPosition(
        this.getNextPlaceholderPosition(cursorPosition, moveDirection)
      );
    }

    this.storeCursorState();
  }

  getCursorMoveDirection(newCursorPosition: number): -1 | 1 {
    return (Math.sign(newCursorPosition - this.previousCursorPosition) || 1) as
      | 1
      | -1;
  }

  private getNextPlaceholderPosition(
    cursorPosition: number,
    direction: -1 | 1
  ): number {
    while (
      this.formatter?.hasNoPlaceholderAt(cursorPosition - 1) &&
      this.isWithinBounds(cursorPosition + direction)
    ) {
      cursorPosition += direction;
    }
    return cursorPosition;
  }

  private isWithinBounds(position: number) {
    return (
      this.ref?.current && position >= 0 && position <= this.getEndBoundary()
    );
  }

  private getEndBoundary(): number {
    return this.ref.current
      ? this.ref.current.value.length - this.suffixLength
      : 0;
  }

  private getCurrentCursorPosition() {
    return this.ref?.current?.selectionStart || 0;
  }

  private setCursorPosition(position: number) {
    if (!this.ref || !this.ref.current) return;

    const { selectionStart, selectionEnd } = this.ref.current;
    const isRange = selectionStart !== selectionEnd;

    this.ref.current.selectionStart = position;
    if (!isRange) this.ref.current.selectionEnd = position;
  }

  private storeCursorState() {
    this.previousCursorPosition = this.getCurrentCursorPosition();
    this.previousCursorOffset =
      this.formatter?.getOffsetAtCursorPosition(this.previousCursorPosition) ||
      0;
  }
}
