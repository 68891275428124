import { Button, Icons } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import { MouseEventHandler, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import styles from './InputInfoBox.module.scss';

export enum InfoType {
  ERROR = 'error',
  WARNING = 'warning',
  TOOLTIP = 'tooltip',
  SUCCESS = 'success',
}

interface IValidationPrompt {
  accept?: string;
  reject?: string;
}

export type IInputInfoBox = {
  message?: ReactNode;
  prompt?: IValidationPrompt;
  children?: ReactNode;
  onAccept?: MouseEventHandler;
  onReject?: MouseEventHandler;
  type?: InfoType;
};

const icon = {
  error: Icons.CircleWarningWhite,
  warning: Icons.CirclePromptBlack,
  tooltip: Icons.CircleInfoBlack,
  success: Icons.CircleCheckMarkValid,
};

interface Props extends IInputInfoBox {
  children?: ReactNode;
  infoId?: string;
}

export const InputInfoBox = ({
  message,
  children,
  prompt,
  onAccept,
  onReject,
  type,
  infoId,
}: Props) => {
  const Icon = type ? icon[type] : undefined;

  return (
    <div className={classNames(styles.container, type && styles[type])}>
      {children}

      {message && (
        <div className={classNames(styles.row, type && styles[type])}>
          {Icon ? <Icon size="sm" /> : null}

          <div className={styles.column}>
            <span id={infoId}>
              <Trans i18nKey={String(message)}>{message}</Trans>
            </span>

            {prompt && (
              <div className={styles.prompt}>
                {prompt.accept && (
                  <Button
                    fontVariant="secondary"
                    width="full-width"
                    variant="white"
                    onClick={(e) => {
                      e.preventDefault();
                      onAccept?.(e);
                    }}
                  >
                    {prompt.accept}
                  </Button>
                )}

                {prompt.reject && (
                  <Button
                    fontVariant="secondary"
                    width="full-width"
                    variant="white"
                    onClick={(e) => {
                      e.preventDefault();
                      onReject?.(e);
                    }}
                  >
                    {prompt.reject}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputInfoBox;
