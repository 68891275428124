import { useState } from 'react';

interface Props<T> {
  onFocus?: (e: React.FocusEvent<T, Element>) => void;
  onBlur?: (e: React.FocusEvent<T, Element>) => void;
}

/**
 * Hook that makes it easier to get the focus state of an element. The returned onFocus and onBlur
 * callbacks must be attached to the element, and the hasFocus state variable will then automatically
 * be updated whenever the focus changes. If you need to do additional event handling on focus or blur
 * these additional event handlers can be passed to the hook as parameters.
 */
export function useHasFocus<T extends HTMLElement>({
  onFocus: onFocusCallback,
  onBlur: onBlurCallback,
}: Props<T> = {}) {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const onFocus = (e: React.FocusEvent<T, Element>) => {
    setHasFocus(true);
    onFocusCallback?.(e);
  };

  const onBlur = (e: React.FocusEvent<T, Element>) => {
    setHasFocus(false);
    onBlurCallback?.(e);
  };

  return {
    hasFocus,
    onFocus,
    onBlur,
  };
}
