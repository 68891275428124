import {
  IMarketCountryKeyParams,
  marketCountryKeys,
} from './marketCountryKeys';

export interface IInsuranceCustomerKeyParams extends IMarketCountryKeyParams {
  insuranceID: string;
  customerID: string;
}

export const insuranceCustomerKeys = {
  root: (params: IInsuranceCustomerKeyParams) =>
    [
      ...marketCountryKeys.root(params),
      'customer',
      params.customerID,
      'insurancePolicy',
      params.insuranceID,
      'customer',
    ] as const,
};

export function parseInsuranceCustomerKey<
  T extends keyof typeof insuranceCustomerKeys
>(
  queryKey: ReturnType<(typeof insuranceCustomerKeys)[T]>
): IInsuranceCustomerKeyParams {
  const [, marketCountry, , customerID, , insuranceID] = queryKey;

  return { insuranceID, customerID, marketCountry };
}
