import { API } from './api';
import { event_log, permissions } from '@axo/shared/data-access/types';
import decode from 'jwt-decode';

export function createEvent(api: API) {
  return async function (event: Omit<event_log.Event, 'CreatedAt' | 'ID'>) {
    const res = await api.send<event_log.Event>({
      data: event,
      method: 'POST',
      url: new URL('/event-log', api.urls.api).toString(),
    });

    return res.data;
  };
}

export interface CreateAuthorizedEventRequest {
  source: string;
  marketCountry: string;
  code: string;
  resources: (event_log.Resource | { Type: KnownResourceType; ID: string })[];
  fields?: { [key: string]: string | undefined };
}

export enum KnownResourceType {
  Customer = 'customer/Customer',
  Client = 'client/Client',
  LoanApplication = 'loan_application/LoanApplication',
  Person = 'person/Person',
  LoanQuote = 'loan_quote/LoanQuote',
  LoanRequest = 'loan_quote/LoanRequest',
  InsurancePolicy = 'insurance/InsurancePolicy',
  InsuranceRequest = 'insurance_request/Request',
  CustomerConsent = 'consent/CustomerConsent',
  Lender = 'lender/Lender',
}

export function createCustomerEvent(api: API) {
  return async function (event: CreateAuthorizedEventRequest) {
    if (!api.token) return;
    const payload = decode<permissions.Identity>(api.token);
    if (!payload.CustomerID) {
      throw new Error(
        `Customer events may only be sent using a token with a CustomerID claim`
      );
    }

    const resources = [
      {
        Type: KnownResourceType.Customer,
        ID: payload.CustomerID,
      },
      ...event.resources,
    ].reduce(
      (acc, current) =>
        acc.find((x) => x.Type === current.Type && x.ID === current.ID)
          ? acc
          : [...acc, current],
      [] as event_log.Resource[]
    );

    try {
      await Promise.race([
        createEvent(api)({
          Origin: { CustomerID: payload.CustomerID, Roles: [] },
          Medium: event_log.Medium.Customer,
          Source: event.source,
          EventCode: event.code,
          MarketCountry: event.marketCountry,
          Resources: resources.map(({ Type, ID }) => ({
            Type: Type.substring(0, 255),
            ID: ID.substring(0, 255),
          })),
          Fields: event.fields
            ? Object.entries(event.fields)
                .filter((kv): kv is [string, string] => kv[1] !== undefined)
                .map(([key, value]) => ({
                  Key: key.substring(0, 255),
                  Value: value.substring(0, 255),
                }))
            : [],
        }),
        new Promise((resolve) => setTimeout(resolve, 250)),
      ]);
    } catch (error) {
      console.warn('failed to send event', error);
    }
  };
}

export function createPersonEvent(api: API) {
  return async function (event: CreateAuthorizedEventRequest) {
    if (!api.token) return;
    const payload = decode<permissions.Identity>(api.token);
    if (!payload.PersonID) {
      throw new Error(
        `Person events may only be sent using a token with a PersonID claim`
      );
    }

    const resources = [
      {
        Type: KnownResourceType.Person,
        ID: payload.PersonID,
      },
      ...event.resources,
    ].reduce(
      (acc, current) =>
        acc.find((x) => x.Type === current.Type && x.ID === current.ID)
          ? acc
          : [...acc, current],
      [] as event_log.Resource[]
    );

    try {
      await Promise.race([
        createEvent(api)({
          Origin: { PersonID: payload.PersonID, Roles: [] },
          Medium: event_log.Medium.Person,
          Source: event.source,
          EventCode: event.code,
          MarketCountry: event.marketCountry,
          Resources: resources.map(({ Type, ID }) => ({
            Type: Type.substring(0, 255),
            ID: ID.substring(0, 255),
          })),
          Fields: event.fields
            ? Object.entries(event.fields)
                .filter((kv): kv is [string, string] => kv[1] !== undefined)
                .map(([key, value]) => ({
                  Key: key.substring(0, 255),
                  Value: value.substring(0, 255),
                }))
            : [],
        }),
        new Promise((resolve) => setTimeout(resolve, 250)),
      ]);
    } catch (error) {
      console.warn('failed to send event', error);
    }
  };
}

export function createClientEvent(api: API) {
  return async function (event: CreateAuthorizedEventRequest) {
    if (!api.token) return;
    const payload = decode<permissions.Identity>(api.token);
    if (!payload.ClientID) {
      throw new Error(
        `Client events may only be sent using a token with a ClientID claim`
      );
    }

    const resources = [
      {
        Type: KnownResourceType.Client,
        ID: payload.ClientID,
      },
      ...event.resources,
    ].reduce(
      (acc, current) =>
        acc.find((x) => x.Type === current.Type && x.ID === current.ID)
          ? acc
          : [...acc, current],
      [] as event_log.Resource[]
    );

    try {
      await Promise.race([
        createEvent(api)({
          Origin: { ClientID: payload.ClientID, Roles: payload.Roles },
          Medium: event_log.Medium.Client,
          Source: event.source,
          EventCode: event.code,
          MarketCountry: event.marketCountry,
          Resources: resources.map(({ Type, ID }) => ({
            Type: Type.substring(0, 255),
            ID: ID.substring(0, 255),
          })),
          Fields: event.fields
            ? Object.entries(event.fields)
                .filter((kv): kv is [string, string] => kv[1] !== undefined)
                .map(([key, value]) => ({
                  Key: key.substring(0, 255),
                  Value: value.substring(0, 255),
                }))
            : [],
        }),
        new Promise((resolve) => setTimeout(resolve, 250)),
      ]);
    } catch (error) {
      console.warn('failed to send event', error);
    }
  };
}
