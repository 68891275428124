import { Caret, ICaret } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '@axo/shared/i18n';
import {
  ForwardRefRenderFunction,
  SelectHTMLAttributes,
  forwardRef,
  useId,
} from 'react';
import { useHasFocus } from '../../hooks/useHasFocus';
import { IInputPrimaryState } from '../../models/input.model';
import InputInfoBox, { IInputInfoBox, InfoType } from '../InputInfoBox';
import InputStateBox from '../InputStateBox';
import InputWrapper from '../InputWrapper';
import styles from './Dropdown.module.scss';

export interface IItemValue {
  value: string | number;
  text: string;
  key?: string; // If not set, the text will be used as the key
}

type IOption = IItemValue | string;

export interface IDropdown extends SelectHTMLAttributes<HTMLSelectElement> {
  options: IOption[];
  placeholder?: string;
  caretProps?: Partial<ICaret>;
  infoBox?: IInputInfoBox;
  label?: string;
  warning?: string;
  state?: IInputPrimaryState;
}

export interface IOptionsDict {
  [index: string]: IOption;
}

export interface IDropDownEvent
  extends React.SyntheticEvent<HTMLSelectElement> {
  target: EventTarget & HTMLSelectElement;
}

export type IDropDownEventHandler = React.EventHandler<IDropDownEvent>;

export const Dropdown: ForwardRefRenderFunction<
  HTMLSelectElement,
  IDropdown
> = (
  {
    options,
    placeholder = '',
    caretProps,
    infoBox,
    label,
    warning,
    state,
    ...props
  },
  ref
) => {
  const { t } = useTranslation();

  const { hasFocus, ...useHasFocusEventHandlers } = useHasFocus(props);
  const id = useId();

  const isError = infoBox?.type === InfoType.ERROR;

  return (
    <InputWrapper htmlFor={id} label={label}>
      <InputInfoBox {...infoBox} infoId={id}>
        <InputStateBox
          primaryState={state}
          icon={<Caret {...caretProps} direction={hasFocus ? 'up' : 'down'} />}
        >
          <select
            className={styles.select}
            ref={ref}
            id={id}
            aria-invalid={isError}
            aria-errormessage={isError ? id : undefined}
            aria-describedby={!isError && infoBox?.type ? id : undefined}
            {...props}
            {...useHasFocusEventHandlers}
          >
            <option disabled value="">
              {placeholder}
            </option>

            {options.map((option) => {
              const { key, value, text } = parseOption(option);
              return (
                <option value={value} key={key}>
                  {t(text)}
                </option>
              );
            })}
          </select>
        </InputStateBox>
      </InputInfoBox>
    </InputWrapper>
  );
};

const parseOption = (option: IOption) => ({
  text: typeof option === 'object' ? option.text : option,
  value: typeof option === 'object' ? option.value : option,
  key: typeof option !== 'object' ? option : option.key || option.text,
});

export default forwardRef(Dropdown);
