import {
  Button,
  IButton,
  IButtonVariant,
  ICSSColorVariable,
  Icons,
} from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import styles from './StepNavigator.module.scss';

type INavigationButtonVariant =
  | 'enabled'
  | 'disabled'
  | 'secondary'
  | 'back'
  | 'ghost'
  | 'link';

export type INavigationButton = {
  text?: string;
  variant: INavigationButtonVariant;
  width?: IButton['width'];
  onClick?: () => void;
  style?: Record<string, string>;
};

const getButtonVariant = (
  variant: INavigationButtonVariant
): IButtonVariant => {
  switch (variant) {
    case 'enabled':
      return 'primary-sweden';
    case 'ghost':
      return 'ghost';
    default:
      return 'secondary';
  }
};

const getColorObject = (
  color: ICSSColorVariable,
  border?: ICSSColorVariable
) => {
  return {
    backgroundColor: `var(${color})`,
    borderColor: `var(${border ?? color})`,
  };
};

const getStyleProp = (variant: INavigationButtonVariant) => {
  switch (variant) {
    case 'enabled':
      return getColorObject('--success-500');
    case 'disabled':
      return getColorObject('--light-300', '--light-700');
    case 'back':
      return getColorObject('--white-100', '--light-500');
    default:
      return undefined;
  }
};

export const NavigationButton = (props: INavigationButton) => {
  if (props.variant === 'link') {
    return (
      <div className={classNames(styles.link)} onClick={props.onClick}>
        {props.text}
      </div>
    );
  }

  return (
    <Button
      variant={getButtonVariant(props.variant)}
      classes={{
        root: classNames(
          styles.button,
          props.variant === 'back' && styles.mini
        ),
      }}
      fontVariant="secondary"
      style={{ ...getStyleProp(props.variant), ...props.style }}
      width={props.width}
      onClick={props.onClick}
    >
      {props.variant === 'back' ? (
        <Icons.Chevron classes={{ root: styles.icon }} />
      ) : (
        props.text
      )}
    </Button>
  );
};
