export const productFactSheetUrl =
  import.meta.env.VITE_APP_INSURANCE_APP_URL + '/Ehdot_Axolaina_Maksuturva_2024-04-18.pdf?inline=true';

export const prePurchaseInformationAndTermsAndConditionsUrl =
  import.meta.env.VITE_APP_INSURANCE_APP_URL + '/Etämyynnin_ennakkotiedot_ja_ehdot_AIG_Axolaina_Maksuturva_2024-04-18.pdf?inline=true';

export const prePurchaseInformationUrl =
  import.meta.env.VITE_APP_INSURANCE_APP_URL + '/Etämyynnin_ennakkotiedot_Axolaina Maksuturva_AIG_2024-04-18.pdf?inline=true';

export const termsAndConditionsUrl =
  'https://17nl0ui4sbms.b-cdn.net/2x5uonqaowle-vakuutusehdot-axolaina-maksuturva-2023-05-02.pdf?inline=true';

export const dataHandlingUrl =
  'https://17nl0ui4sbms.b-cdn.net/2nsb31qnisfh-2bag1gakbovr-ehdot-axolaina-turvavakuutus-2023-04-27.pdf?inline=true';
