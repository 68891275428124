import { ReactNode } from 'react';
import { Label } from '@axo/deprecated/util/ui-components';

enum ClassNames {
  label = 'label',
  root = 'root',
  gap = 'gap',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface IInputWrapper {
  children?: ReactNode;
  classes?: ClassNamesProp;
  htmlFor?: string;
  labelId?: string;
  label?: string;
}

export const InputWrapper = ({
  children,
  classes,
  htmlFor,
  labelId,
  label,
}: IInputWrapper) => {
  return (
    <div className={classes?.root}>
      <Label
        id={labelId}
        htmlFor={htmlFor}
        labelText={label}
        className={classes?.label}
      />
      {children}
    </div>
  );
};

export default InputWrapper;
