import {
  Component,
  ErrorInfo,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

export type FallbackComponent =
  | ReactNode
  | ReactElement<{ resetErrorBoundary: () => void }>;

interface Props {
  children: ReactNode;
  fallback?: FallbackComponent;
  onError?(error: Error, errorInfo: ErrorInfo): void;
  preventMultipleOnErrorCalls?: boolean;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

const initialState = {
  hasError: false,
  error: null,
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = initialState;
  }
  hasCalledOnError = false;

  resetErrorBoundary() {
    if (this.state.hasError) {
      this.setState(initialState);
      this.hasCalledOnError = false;
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Uncaught error:', error, errorInfo);
    // If provided, call the onError prop on first error only when preventMultipleOnErrorCalls is set to true.
    const canCallOnError =
      this.hasCalledOnError === false ||
      this.props.preventMultipleOnErrorCalls === false;
    if (canCallOnError) {
      if (this.props.onError) {
        this.props.onError(error, errorInfo);
        this.hasCalledOnError = true;
      }
    }
  }

  public render() {
    const { hasError } = this.state;
    if (hasError) {
      const { fallback } = this.props;
      if (isValidElement(fallback) && typeof fallback.type !== 'string') {
        // Inject the resetErrorBoundary method into the fallback component
        return cloneElement(
          fallback as ReactElement<{ resetErrorBoundary: () => void }>,
          {
            resetErrorBoundary: this.resetErrorBoundary,
          }
        );
      } else {
        // Fallback to default behavior if fallback is not a React element
        return fallback;
      }
    }
    return this.props.children;
  }
}
