import { useEventLogger } from '@axo/shared/data-access/event-log';
import { ErrorBoundary } from '@axo/shared/feature/error-boundary';
import { useTranslation, useTranslationContext } from '@axo/shared/i18n';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { ErrorView } from '../../../components/ErrorView';

export function LoggingErrorBoundary({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const { ns: translationNamespace } = useTranslationContext();
  const log = useEventLogger();
  return (
    <ErrorBoundary
      fallback={
        <ErrorView
          title={t('Something went wrong')}
          description={
            <Trans ns={translationNamespace}>
              Call customer service if the problem persists <a href="tel:0942452326">09 4245 2326</a>
            </Trans>
          }
        />
      }
      preventMultipleOnErrorCalls={true}
      onError={(error, errorInfo) =>
        log('unhandled-error', {
          message: error.message,
          name: error.name,
          stack: error.stack?.toString(),
          componentStack: errorInfo?.componentStack || undefined,
        })
      }
    >
      {children}
    </ErrorBoundary>
  );
}
