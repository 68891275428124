import mixpanel from 'mixpanel-browser';
import {
  AnalyticsEvent,
  AnalyticsIdentify,
  IAnalytics,
} from '../Analytics.types';
import {
  IAnalyticsService,
  IAnalyticsServiceParams,
} from '../AnalyticsService.types';
import { waitUntil } from '../utils/waitUntil';
import * as Sentry from '@sentry/browser';

/**
 * Sentry Service
 *
 * @see https://docs.sentry.io/platforms/javascript/
 */
export const SentryService: IAnalyticsService = (
  params: IAnalyticsServiceParams = {}
): IAnalytics => {
  const hasServicePresent = Sentry.isInitialized();

  if (!hasServicePresent) {
    console.log('SentryService not found');
  }

  const waitForInitialization = () => waitUntil(isInitialized);
  const isInitialized = () => Sentry.isInitialized();

  const track = async (e: AnalyticsEvent) => {
    return;
  };

  /**
   * Identify a user by their unique ID.
   *
   * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
   *
   * @note sets convenience links on mixpanel
   */
  const identify = async ({ uuid, email }: AnalyticsIdentify) => {
    if (!hasServicePresent)
      return Promise.reject(new Error('SentryService service is not present'));

    Sentry.setUser({ id: uuid, email });
    // add convenience link to mixpanel person
    mixpanel?.people.set_once({
      'sentry issues': `https://axo-finance.sentry.io/issues/?query=is:unresolved%20issue.priority:%5Bhigh,%20medium%5D%20user.id:${uuid}`,
      'sentry replays': `https://axo-finance.sentry.io/replays/?query=user.id%3A${uuid}`,
    });
  };

  return {
    waitForInitialization,
    isInitialized,
    track,
    identify,
  };
};
