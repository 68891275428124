import { ErrorText, Span } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';
import {
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useEffect,
  useId,
  useState,
} from 'react';
import sanitizeHtml from 'sanitize-html';
import { ErrorModel } from '../../models/error.model';
import { IInputProps } from '../../models/input.model';
import Check from '../Check';

import styles from './Checkbox.module.scss';

enum ClassNames {
  Root = 'root',
}

type ClassNamesProp = { [Property in ClassNames]?: string };

export interface ICheckbox
  extends IInputProps<HTMLInputElement, boolean | undefined> {
  errors?: ErrorModel[];
  children?: ReactNode;
  label?: string;
  classes?: ClassNamesProp;
  testid?: string;
  size?: 'small' | 'medium';
  isNoMargin?: boolean;
}

/**
 * @deprecated DO NOT TOUCH THIS CSS !!! the html structure + margin approach is totally wrong, touching anything here will guarantee break somewhere
 */
export const Checkbox: ForwardRefRenderFunction<HTMLInputElement, ICheckbox> = (
  {
    children = '',
    value,
    errors = [],
    name,
    label,
    onChange,
    onFocus,
    onBlur,
    onClick,
    classes,
    testid,
    size = 'medium',
    isNoMargin = false,
  },
  ref
) => {
  const inputId = useId();
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => setIsChecked(value), [value]);

  return (
    <label
      className={classNames(
        styles.checkbox,
        styles[`checkbox--${size}`],
        isNoMargin && styles['checkbox--no-margin'],
        isChecked ? styles.checked : styles.unchecked,
        errors.length > 0 && styles.invalid,
        classes?.root
      )}
      htmlFor={inputId}
    >
      <div className={styles.row}>
        <div className={styles.checkmark}>
          <Check state={errors.length === 0 ? 'valid' : 'invalid'} />
        </div>
        <input
          ref={ref}
          id={inputId}
          name={name}
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked ?? false}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            onChange?.(e);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          data-testid={testid}
        />
        <div className={styles.column}>
          {label && (
            <Span dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }} />
          )}
          {children && <Span>{children}</Span>}

          {errors.length > 0 && <ErrorText errors={errors} />}
        </div>
      </div>
    </label>
  );
};

export default forwardRef(Checkbox);
