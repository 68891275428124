import { PageSpinner } from '@axo/insurance/ui';
import { ReactNode } from 'react';
import { useApplication, useUser } from '../hooks';

interface ILogin {
  children: ReactNode;
  onError: (error?: Error) => void;
}

export function AuthProvider({ children, onError }: ILogin) {
  const { user, error: userError } = useUser();
  const { isLoading, error: appError } = useApplication();

  const error = userError || appError;
  if (error) onError(error);

  if (isLoading || !user || error) {
    return <PageSpinner />;
  }

  return <>{children}</>;
}
