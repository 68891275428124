import { useRef, useState } from 'react';

export type IUseQueue<T> = {
  /** Add an item to the end of the queue */
  add: (item: T) => void;
  /** Remove the first item from the queue and return it */
  remove: () => T | undefined;
  /** Clear the queue */
  clear: () => void;
  /** Get the first item in the queue */
  first: () => T | undefined;
  /** Get the last item in the queue */
  last: () => T | undefined;
  /** Get the size of the queue */
  size: () => number;
  /** Get the queue */
  queue: () => T[];
};

/**
 * A hook to manage a queue
 * @param initialValue - The initial value of the queue
 */
export const useQueue = <T>(initialValue: T[] = []): IUseQueue<T> => {
  const queueRef = useRef<T[]>(initialValue);

  const add = (item: T) => {
    queueRef.current = [...queueRef.current, item];
  };
  const remove = () => queueRef.current.shift();
  const clear = () => (queueRef.current = []);
  const first = () => queueRef.current[0];
  const last = () => queueRef.current[queueRef.current.length - 1];
  const size = () => queueRef.current.length;
  const queue = () => [...queueRef.current];

  return {
    add,
    remove,
    clear,
    first,
    last,
    size,
    queue,
  };
};
