import { ReactNode, useCallback, useMemo } from 'react';
import { API } from '../api';
import {
  createPersonEvent,
  createCustomerEvent,
  createClientEvent,
} from '../data-access-event-log';
import { fetchAPI } from '../fetch-api';
import { BreadcrumbProvider } from './breadcrumb-provider';
import { EventContext, Log } from './event-log-context';
import decode from 'jwt-decode';
import { permissions } from '@axo/shared/data-access/types';

export function MockEventLogProvider({
  children,
  log,
}: {
  children: ReactNode;
  log: Log;
}) {
  return (
    <BreadcrumbProvider>
      <EventContext.Provider value={{ log }}>{children}</EventContext.Provider>
    </BreadcrumbProvider>
  );
}

export function EventLogProvider({
  token,
  apiURL,
  children,
  marketCountry,
  source,
}: {
  children: ReactNode;
  token: string;
  apiURL: string;
  marketCountry: string;
  source: string;
}) {
  const createEvent = useMemo(() => {
    const api: API = {
      send: fetchAPI({
        Authorization: `Bearer ${token}`,
      }),
      token,
      urls: {
        api: apiURL,
      },
    };
    if (!api.token) return;
    const payload = decode<permissions.Identity>(api.token);
    if (payload.CustomerID) {
      return createCustomerEvent(api);
    } else if (payload.PersonID) {
      return createPersonEvent(api);
    } else if (payload.ClientID) {
      return createClientEvent(api);
    }
  }, [token, apiURL]);

  const log: Log = useCallback(
    async (code, fields, ...resources) => {
      if (createEvent) {
        await createEvent({
          marketCountry,
          source,
          code,
          resources,
          fields,
        });
      }
    },
    [createEvent, marketCountry, source]
  );

  return (
    <BreadcrumbProvider>
      <EventContext.Provider value={{ log }}>{children}</EventContext.Provider>
    </BreadcrumbProvider>
  );
}
