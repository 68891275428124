'use client';

import { useAnalytics } from '@axo/shared/services/analytics';
import { useEffect } from 'react';
import { FlagSetMap } from './Flag.types';

/**
 * Tracks an impression of a Flag,
 * sends the event to the registered Analytics services.
 * The event is only tracked once per session
 *
 * @param flagSet - An object where the keys start with 'flag_' and the values are LD flag keys.
 * @param flags - The flags to track
 *
 * @todo https://docs.mixpanel.com/docs/tracking-methods/integrations/launchdarkly
 * ```
 * {
 *   "event": "$experiment_started",
 *   "properties": {
 *     "distinct_id": "alice@example.com",
 *     "time": 1709275888,
 *     "Experiment name": "my_experiment",
 *     "Variant name": "control"
 *   }
 * }
 * ```
 *
 */
export function useFlagImpression<T extends FlagSetMap<T>>(
  flagSet: T,
  flags: { [key: string]: unknown }
) {
  const { track } = useAnalytics();

  useEffect(() => {
    for (const [flagName, variation] of Object.entries(flags)) {
      const hasImpression = sessionStorage.getItem(`ff_${flagName}`);

      const LDName = flagSet[flagName as keyof T];
      if (LDName === undefined) {
        throw new Error(`Flag ${flagName} not found in flagSet`);
      }
      if (hasImpression) continue;
      if (variation === undefined) continue;

      sessionStorage.setItem(`ff_${flagName}`, JSON.stringify(variation));

      track({
        event: 'Feature Flag Viewed',
        params: {
          flagName: LDName,
          variation,
        },
      });
    }
  }, [flagSet, flags, track]);
}
