import { FC, useLayoutEffect, useRef, useState } from 'react';
import classNames from '../../../utils/class-names';
import styles from './Tooltip.module.scss';
export interface TooltipProps {
  text?: string;
  children?: React.ReactNode;
  classes?: ClassNamesProp;
}

type ClassNamesProp = { root?: string; tooltip?: string; text?: string };

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Tooltip: FC<TooltipProps> = ({ text, children, classes }) => {
  const tooltipContainer = useRef<HTMLDivElement>(null);
  const arrow = useRef<SVGSVGElement>(null);
  const [leftPosition, setLeftPosition] = useState(0);

  useLayoutEffect(() => {
    function handleOffsetTooltip() {
      // Check for existence of window before calling getComputedStyle.
      if (!window || !tooltipContainer.current || !arrow.current) return;

      const rectTooltip = tooltipContainer.current.getBoundingClientRect();
      const rectArrow = arrow.current.getBoundingClientRect();

      const documentWidth = document.documentElement.clientWidth;
      const overflowPixelsRight =
        rectTooltip.right > documentWidth
          ? rectTooltip.right - documentWidth
          : 0;
      const maxOffset = rectTooltip.left;
      
      const arrowCorrection = rectArrow.right - rectTooltip.left;
      if (overflowPixelsRight > 0) {
        const offset = Math.min(maxOffset, rectTooltip.width - arrowCorrection);
        setLeftPosition(-Math.max(offset, 0));
      }
    }
    handleOffsetTooltip();
  }, []);

  return (
    <div className={classNames(styles.root, classes?.root)}>
      {text && (
        <>
          <div
            className={classNames(styles.tooltip, classes?.tooltip)}
            style={{ left: `${leftPosition}px` }}
          >
            <div
              className={classNames(styles.box, classes?.text)}
              ref={tooltipContainer}
            >
              {text}
            </div>
          </div>
          <div className={styles.arrowContainer}>
            <svg ref={arrow} viewBox="0 0 10 8" className={styles.arrow}>
              <path d="M0 0 H10 L5 8 Z"></path>
            </svg>
          </div>
        </>
      )}
      {children}
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Tooltip;
