import { Icons } from '@axo/deprecated/util/ui-components';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import styles from './AccordionItem.module.scss';

type IAccordionItemHeaderProps = {
  children: ReactNode;
  className?: string;
};

export const AccordionItemHeader = forwardRef(
  (
    { children, className, ...props }: IAccordionItemHeaderProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ): ReactElement => (
    <RadixAccordion.Header className={styles.accordionItemHeader}>
      <RadixAccordion.Trigger
        ref={forwardedRef}
        className={clsx(styles.trigger, className)}
        {...props}
      >
        <div className={styles.content}>{children}</div>
        <div className={styles.icon}>
          <Icons.Chevron aria-hidden />
        </div>
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  )
);
