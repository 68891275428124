export interface InsurancePolicy {
  ID: string;
  BrokerClientID: string;
  MarketCountry: string;
  CustomerID: string;
  PersonID: string;
  Amount: number;
}

export enum InsuranceSourceTagEnum {
  'application-form-flow',
  'my-page-select-offer',
  'telemarketing-form',
  'credit-card-flow',
  'my-page-insurance-overview',
  'sms-offer',
}

export type InsuranceSourceTag = keyof typeof InsuranceSourceTagEnum;
