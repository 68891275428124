import { SelectCoverage } from './steps/SelectCoverage';
import { Explainer } from './steps/Explainer';
import { VerifyPaymentCard } from './steps/VerifyPaymentCard';
import { PowerOfAttorney } from './steps/PowerOfAttorney';
import { Terms } from './steps/Terms';
import { Summary } from './steps/Summary';
import '@axo/shared/ui/core/styles/mixins.scss';
import {
  AppSpecificStepProviderProps,
  StepList,
  StepProvider,
  stepperReducer,
  useInsuranceLogging,
} from '@axo/insurance/feature/providers';
import { getInitialStepContextState } from '../shared';
import { useReducer } from 'react';
import { trustpilotConfig } from '@axo/insurance/const/fi';
import { ITrustpilot } from '@axo/shared/ui/core';

export const StepProviderFinland = (props: AppSpecificStepProviderProps) => {
  const search = new URLSearchParams(window.location.search);
  const initialState = getInitialStepContextState(search);
  const [state, dispatch] = useReducer(stepperReducer, initialState);
  useInsuranceLogging(state);

  return (
    <StepProvider
      steps={steps}
      state={state}
      dispatch={dispatch}
      locale="fi"
      trustpilotConfig={
        trustpilotConfig as Pick<ITrustpilot, 'businessId' | 'locale' | 'link'>
      }
      {...props}
    />
  );
};

const steps: StepList = {
  explainer: <Explainer />,
  selectCoverage: <SelectCoverage />,
  terms: <Terms />,
  powerOfAttorney: <PowerOfAttorney />,
  verifyPaymentCard: <VerifyPaymentCard />,
  summary: <Summary />,
};
