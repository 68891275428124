import { useCallback, useContext, useRef } from 'react';
import { event_log } from '@axo/shared/data-access/types';
import { BreadcrumbContext } from './breadcrumb-context';
import { Log, useEventLog } from './event-log-context';
import { CreateAuthorizedEventRequest } from '../data-access-event-log';

export function useEventLogger(...staticResources: event_log.Resource[]) {
  const { log } = useEventLog();
  const tags = useRef(staticResources);
  const breadcrumbContext = useContext(BreadcrumbContext);
  if (!breadcrumbContext) throw new Error(`No BreadcrumbContext found!`);
  const { getResources, getFields } = breadcrumbContext;
  const combinedResources = useCallback(
    (resources: event_log.Resource[]) => [
      ...getResources(),
      ...tags.current,
      ...resources,
    ],
    [getResources]
  );
  const combinedFields = useCallback(
    (fields: CreateAuthorizedEventRequest['fields']) =>
      getFields().reduce(
        (acc, x) => ({ ...acc, [x.Key]: x.Value }),
        fields ?? {}
      ),
    [getFields]
  );
  return useCallback<Log>(
    (code, fields, ...resources) =>
      log(code, combinedFields(fields), ...combinedResources(resources)),
    [log, combinedFields, combinedResources]
  );
}
