import { ReactNode, useCallback, useContext, useRef } from 'react';
import { event_log } from '@axo/shared/data-access/types';
import { BreadcrumbContext } from './breadcrumb-context';

export function BreadcrumbProvider({
  resources,
  fields,
  children,
}: {
  resources?: event_log.Resource[];
  fields?: event_log.Field[];
  children: ReactNode;
}) {
  const staticResources = useRef(resources ?? []);
  const staticFields = useRef(fields ?? []);
  const resourcesState = useRef<event_log.Resource[]>([]);
  const fieldsState = useRef<event_log.Field[]>([]);
  const parent = useContext(BreadcrumbContext);

  const addResource = useCallback(function (resource: event_log.Resource) {
    resourcesState.current = [...resourcesState.current, resource];

    return () => {
      resourcesState.current = resourcesState.current.filter(
        (r) => r !== resource
      );
    };
  }, []);
  const addField = useCallback(function (field: event_log.Field) {
    fieldsState.current = [...fieldsState.current, field];

    return () => {
      fieldsState.current = fieldsState.current.filter((r) => r !== field);
    };
  }, []);

  const getParentResources = parent?.getResources;
  const getResources = useCallback(() => {
    return [
      ...(getParentResources ? getParentResources() : []),
      ...staticResources.current,
      ...resourcesState.current,
    ];
  }, [getParentResources]);
  const getParentFields = parent?.getFields;
  const getFields = useCallback(() => {
    return [
      ...(getParentFields ? getParentFields() : []),
      ...staticFields.current,
      ...fieldsState.current,
    ];
  }, [getParentFields]);

  return (
    <BreadcrumbContext.Provider
      value={{ getResources, getFields, addResource, addField }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
}
