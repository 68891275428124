import clsx from 'clsx';
import { ElementType, ReactElement, ReactNode } from 'react';
import styles from './heading.module.scss';

export const sizes = ['xxs', 'xs', 's', 'm', 'l'] as const;
export const asHeadingElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

const SizeElementMap: Record<
  NonNullable<HeadingProps['size']>,
  HeadingProps['as']
> = {
  l: 'h1',
  m: 'h2',
  s: 'h3',
  xs: 'h4',
  xxs: 'h5',
};

type HeadingProps = {
  children: ReactNode;
  size?: (typeof sizes)[number];
  as?: (typeof asHeadingElements)[number];
  className?: string;
} & HTMLDataAttributes;

/**
 * Heading component for generic heading rendering
 *
 * @warning Respect the heading hierarchy for SEO and accessibility.
 */
export const Heading = ({
  children,
  size = 'l',
  as,
  className,
  ...props
}: HeadingProps): ReactElement => {
  const Element = (as || SizeElementMap[size]) as ElementType;

  return (
    <Element
      className={clsx(styles.heading, className)}
      data-size={size}
      {...props}
    >
      {children}
    </Element>
  );
};
