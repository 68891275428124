/* eslint-disable axo-frontend/directory-structure */
import { Hr, Icons } from '@axo/deprecated/util/ui-components';
import {
  StepContext,
  useCreateInsuranceEvent,
} from '@axo/insurance/feature/providers';
import {
  ContentBoxWrapper,
  NavWrapper,
  StepNavigator,
} from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useTranslation } from '@axo/shared/i18n';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext } from 'react';
import styles from './Explainer.module.scss';

export const Explainer = () => {
  const { dispatch, state, isEmbedded, onEnd, onAccept } =
    useContext(StepContext);
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const addEvent = useCreateInsuranceEvent();
  const log = useEventLogger();
  useEventField('current-step', 'explainer');
  const { track } = useAnalytics();
  const { t } = useTranslation();

  const {
    steps: { explainer: stepData },
  } = state;

  if (!['completed', 'active'].includes(stepData.state)) {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'active' },
    });
  }

  function handleNext() {
    log(EventCode.InsuranceNext);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'completed' },
    });
    track({
      event: `Insurance Step ${stepData.name} Completed`,
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'selectCoverage',
    });
    if (onAccept) {
      onAccept.action();
    }
  }

  async function handleDeclineInsurance() {
    log(EventCode.InsuranceDeclined);
    addEvent('fi/form/insurance-declined', 'explainer');
    await track({
      event: 'Insurance Declined',
      options: { send_immediately: true },
      params: { step: 'explainer' },
    });

    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  return (
    <>
      <ContentBoxWrapper tagText={t('Popular')} isEmbedded={isEmbedded}>
        <div className={styles.shield}>
          <Icons.Shield size="lg" />
          <h3 className={styles.productHeader}>{t('Axolaina Insurance')}</h3>
        </div>
        <Hr className={styles.productHeaderHorizontalSeparator} />
        <div className={styles.content}>
          <p>{t('Before you proceed, would you like to add insurance')}</p>

          <ul>
            <li className={styles.listContent}>
              <Icons.Lifesaver size="sm" />
              {t(
                'Take care of your payments with peace of mind in an emergency'
              )}
            </li>
            <li className={styles.listContent}>
              <Icons.ShieldOutline size="sm" />
              {t(
                'Keep your finances stable when unemployment hits or you get sick'
              )}
            </li>
            <li className={styles.listContent}>
              <Icons.Money size="sm" />
              {t('The compensation is paid directly to your account')}
            </li>
            <li className={styles.listContent}>
              <Icons.Globe size="sm" />
              {t(
                'Ensure insurance is valid 24 hours a day and around the world for the first 12 months'
              )}
            </li>
            <li className={styles.listContent}>
              <Icons.Reload size="sm" />
              {t(
                'To change the amount of insurance claim or terminate your insurance at any time'
              )}
            </li>
            <li className={styles.listContent}>
              <Icons.Emergency size="sm" />
              {t(
                'Our Maksuturva insurance covers a lump sum in the event of death'
              )}
            </li>
          </ul>
        </div>
      </ContentBoxWrapper>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          leftButton={{
            width: 'standard',
            text: t('Maybe later'),
            variant: 'secondary',
            onClick: () => handleDeclineInsurance(),
          }}
          rightButton={{
            width: 'standard',
            text: t('Next'),
            variant: 'enabled',
            onClick: () => handleNext(),
          }}
        />
      </NavWrapper>
    </>
  );
};
