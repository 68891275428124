import { ws_connection } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function createTicket({ url: { service: baseURL }, token }: IAPI) {
  return async function (): Promise<ws_connection.Ticket> {
    const url = new URL(`/ws-connection/ticket`, baseURL);
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return await response.json();
  };
}
