import { payoutAndPremium as payoutAndPremiumFi } from '@axo/insurance/const/fi';
import { payoutAndPremium as payoutAndPremiumNo } from '@axo/insurance/const/no';
import { useCallback, useContext } from 'react';
import { StepContext } from '../StepProvider';

const payoutAndPremium = {
  no: payoutAndPremiumNo,
  fi: payoutAndPremiumFi,
};

export const usePayoutAndPremium = (locale: 'fi' | 'no') => {
  const { state } = useContext(StepContext);
  const {
    steps: { selectCoverage: stepData },
  } = state;

  const getPayoutAndPremiumItem = useCallback(
    (amount: number) =>
      payoutAndPremium[locale].find(
        (insuranceSet) => amount === insuranceSet.payout
      ) || stepData.insurance,
    [locale, stepData.insurance]
  );

  return {
    getPayoutAndPremiumItem,
  };
};
