import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { ICSSColorVariable } from '../../../models/color.model';
import classNames from '../../../utils/class-names';
import styles from './ContentBox.module.scss';

export interface IContentBox {
  children: ReactNode;
  active?: boolean;
  activeColour?: ICSSColorVariable;
  tagText?: string;
  tagIcon?: ReactNode;
  classes?: ClassNamesProp;
}

type ClassNamesProp = {
  root?: string;
  tag?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const ContentBox = forwardRef(
  (
    { children, active, activeColour, tagText, tagIcon, classes }: IContentBox,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={classNames(
        styles.container,
        active && styles.active,
        tagText && styles.hasTag,
        classes?.root
      )}
      style={
        activeColour && active ? { borderColor: `var(${activeColour})` } : {}
      }
      data-testid="container"
    >
      {(tagText || tagIcon) && (
        <div className={styles.tagContainer}>
          <span
            className={classNames(styles.tag, classes?.tag)}
            style={
              activeColour ? { backgroundColor: `var(${activeColour})` } : {}
            }
          >
            {tagIcon && tagIcon}
            {tagText}
          </span>
        </div>
      )}
      {children}
    </div>
  )
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default ContentBox;
