import { useState } from 'react';

/**
 * Simple hook that uses Math.random to generate a random string and returns it as a state variable.
 * The state variable is intended to be constant, which is why the update function is not returned.
 */
/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default function useGeneratedId() {
  const [id] = useState<string>(() =>
    Math.random().toString().replace(/0?\./, '')
  );
  return id;
}
