import { createContext, useContext } from 'react';
import { event_log } from '@axo/shared/data-access/types';
import { CreateAuthorizedEventRequest } from '../data-access-event-log';

export const EventContext = createContext<IEventContext | null>(null);

export type Log = (
  code: string,
  fields?: CreateAuthorizedEventRequest['fields'],
  ...resources: event_log.Resource[]
) => Promise<void>;

interface IEventContext {
  log: Log;
}

export function useEventLog() {
  const context = useContext(EventContext);
  if (!context) throw new Error(`No EventContext found!`);
  return context;
}
