import { useContext, useEffect } from 'react';
import { event_log } from '@axo/shared/data-access/types';
import { BreadcrumbContext } from './breadcrumb-context';
import { KnownResourceType } from '../data-access-event-log';

export function useEventResource(
  resourceType: event_log.Resource['Type'] | KnownResourceType,
  resourceID: event_log.Resource['ID'] | null | undefined
) {
  const context = useContext(BreadcrumbContext);
  if (!context) throw new Error('No BreadcrumbContext found!');
  const { addResource } = context;

  useEffect(() => {
    if (resourceID) {
      return addResource({ Type: resourceType, ID: resourceID });
    }
    return undefined;
  }, [addResource, resourceID, resourceType]);
}
