import { FC } from 'react';
import styles from './Check.module.scss';
import { classNames } from '@axo/shared/util/dom';

export interface ICheck {
  state:
    | 'unfocused'
    | 'focused'
    | 'valid'
    | 'invalid'
    | 'disabled'
    | 'inactive'
    | 'info';
  circle?: boolean;
}

export const Check: FC<ICheck> = ({ state, circle }: ICheck) => {
  return (
    <svg
      role="img"
      className={classNames(
        styles.check,
        styles[state],
        circle && styles.circle
      )}
      fill="none"
      viewBox="0 -1 14 14"
    >
      <title>{`${state} checkmark icon`}</title>
      <path
        d="m12.552 1.833-7.037 8.333-4.075-3.41"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Check;
