import { forwardRef, ForwardRefRenderFunction, useId } from 'react';
import FormattedInput, { IFormattedInput } from '../FormattedInput';
import InputInfoBox, { IInputInfoBox, InfoType } from '../InputInfoBox';
import InputStateBox from '../InputStateBox';
import InputWrapper from '../InputWrapper';
import { IInputPrimaryState } from '../../models/input.model';

export interface ITextInput extends IFormattedInput {
  infoBox?: IInputInfoBox;
  label?: string;
  warning?: string;
  state?: IInputPrimaryState;
}

const TextInput: ForwardRefRenderFunction<HTMLInputElement, ITextInput> = (
  { infoBox, label, warning, state, ...props },
  ref
) => {
  const inputId = useId();
  const infoId = useId();

  const isError = infoBox?.type === InfoType.ERROR;

  return (
    <InputWrapper htmlFor={inputId} label={label}>
      <InputInfoBox {...infoBox} infoId={infoId}>
        <InputStateBox primaryState={state}>
          <FormattedInput
            autoComplete={'off'}
            type="text"
            id={inputId}
            aria-invalid={isError}
            aria-errormessage={isError ? infoId : undefined}
            aria-describedby={!isError && infoBox?.type ? infoId : undefined}
            {...props}
            ref={ref}
          ></FormattedInput>
        </InputStateBox>
      </InputInfoBox>
    </InputWrapper>
  );
};

export default forwardRef(TextInput);
