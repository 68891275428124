import { useMutation, useQueryClient } from 'react-query';
import { person } from '@axo/shared/data-access/types';
import { person_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { personKeys } from '../cache-keys/personKeys';

export function usePatchPerson() {
  const client = useQueryClient();
  const api = useAPI();
  return useMutation(
    (request: person.Patch) =>
      person_api.patchPerson(api)(request.id, request.data),
    {
      onSuccess: (x) => {
        const cacheKey = personKeys.root({
          personID: x.ID,
        });
        client.invalidateQueries(cacheKey);
      },
    }
  );
}
