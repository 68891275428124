import { AnonymousUserLDContext, UserLDContext } from '../LDContext.types';

export const getAnonymousUserLDContext = (): {
  user: AnonymousUserLDContext;
} => ({
  user: {
    key: 'user',
    anonymous: true,
  } as AnonymousUserLDContext,
});

export const getUserLDContext = (uuid: string, email?: string) => ({
  user: {
    kind: 'user',
    key: uuid,
    uuid,
    email,
  } as UserLDContext,
});
